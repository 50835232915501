const pgSettingsConfig = [
  {
    type: "input_number",
    inputType: "number",
    label: "Data Interval (secs)",
    model: "data_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Sampling Interval (secs)",
    model: "sampling_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "DO Calibration Value",
    model: "do_calibration_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph4 Calibration Value",
    model: "ph4_calibration_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph7 Calibration Value",
    model: "ph7_calibration_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph9 Calibration Value",
    model: "ph9_calibration_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph4 Temp Value (\u00b0C)",
    model: "ph4_temperature_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Diagnose Interval (mins)",
    model: "diagnose_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Data Reset Interval (secs)",
    model: "data_reset_interval",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "ph Sensor Control",
    model: "ph_sensor_control",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph Slope",
    model: "ph_slope",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Tempature Sensor Control",
    model: "temperature_sensor_control",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Do Sensor Control",
    model: "do_sensor_control",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph7 Temp Value (\u00b0C)",
    model: "ph7_temperature_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Self Cleaning Threshold Voltage",
    model: "self_cleaning_threshold_voltage",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph9 Temp Value (\u00b0C)",
    model: "ph9_temperature_value",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Temperature Correction Factor",
    model: "temperature_correction_factor",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "DO Correction Factor",
    model: "do_correction_factor",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph Correction Factor",
    model: "ph_correction_factor",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "LCD Sleep Mode",
    model: "lcd_sleep_mode",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "LCD Sleep Interval (secs)",
    model: "lcd_sleep_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "ph ON Interval (secs)",
    model: "ph_on_interval",
    validationRules: "required"
  },

  {
    type: "input_number",
    inputType: "number",
    label: "Self Cleaning Interval (secs)",
    model: "self_cleaning_interval",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Self Cleaning ON Time (secs)",
    model: "self_clean_on_time",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Salinity (ppt)",
    model: "salinity",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Pressure (kpa)",
    model: "pressure",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Pond Depth (mm)",
    model: "pond_depth",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Distance from water surface (ft)",
    model: "distance_from_water_surface",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "Software Version",
    model: "software_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Hardware Version",
    model: "hardware_version",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Low Do",
    model: "lower_limit",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Critical Do",
    model: "critical_lower_limit",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Minimum Temperature",
    model: "min_temperature",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Maximum Temperature",
    model: "max_temperature",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "Version",
    model: "version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Mechanical Version",
    model: "mech_version",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Cleaning On Time (secs)",
    model: "cleaning_on_time",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Height From Ground (mm)",
    model: "device_height_from_ground",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Height From Water (mm)",
    model: "device_height_from_water",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Auto Calibration Interval (days)",
    model: "auto_calib_interval_days",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Auto Cleans per day",
    model: "auto_cleans_per_day",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Auto Cleaning Enable",
    model: "auto_cleaning_enable",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Auto Calibration Enable",
    model: "auto_calibration_enable",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Group Settings",
    model: "group_settings",
    validationRules: { validFormat: { format: "JSON" } }
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Salinity Sensor",
    model: "salinity_sensor_enable",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "DO Saturation",
    model: "do_saturation_sensor_enable",
    validationRules: ""
  }
].map(keyConfig => {
  return {
    ...keyConfig,
    property: keyConfig.model,
    getMethodParams: ({ activityLog }) => [
      activityLog,
      `data.${keyConfig.model}`
    ]
  };
});

export default pgSettingsConfig;
