<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: langaugeSelect.vue
Description: This file contains the select component for language selection in pondlogs site
-->
<template>
  <div class="lang-select-container">
    <!-- <span class="lang-select-label">{{ $t("languages") }}&nbsp;</span> -->
    <er-select v-model="langSelected" size="mini">
      <el-option
        v-for="(value, key) in langs"
        :key="`Lang-${key}`"
        :value="key"
        >{{ key }}</el-option
      >
    </er-select>
  </div>
</template>

<script>
import erSelect from "@/components/base/erSelect";
export default {
  data: function () {
    return {
      langs: { Español: "es", English: "en", Chinese: "zh", Portuguese: "pt", Vietnamese: "vi" },
      revLangs: { es: "Español", en: "English", zh: "Chinese", pt: "Portuguese", vi: "Vietnamese" }
    };
  },
  computed: {
    langSelected: {
      get () {
        return this.revLangs[this.$i18n.locale || localStorage.getItem("lang")];
      },
      set (value) {
        this.$i18n.locale = this.langs[value];
        localStorage.setItem(`lang`, this.langs[value]);
      }
    }
  },
  components: {
    erSelect
  }
};
</script>

<style lang="scss">
.lang-select-container {
  .lang-select-label {
    font-size: 14px;
    vertical-align: middle;
    line-height: 1.8;
  }
  .er-select {
    .el-input {
      font-size: 14px;
      .el-select__caret {
        color: black;
      }
      .el-input__inner {
        border: none;
        border-radius: 1px;
        border-left: 2px solid black;
        background-color: #a7a7a738;
        color: white;
      }
    }
    // .el-input__inner {
    //   border: none;
    //   background-color: transparent;
    //   color: white;
    // }
  }
}
</style>
