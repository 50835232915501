const pmSettingsConfig = [
  {
    type: "input_number",
    inputType: "number",
    label: "Kg Dispsence Time (secs)",
    model: "kg_dispense_time",

    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Doser Hall Status",
    model: "doser_hall_status",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Data Interval (mins)",
    model: "data_interval",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Retain Previous Day Schedule",
    model: "retain_previous_day_schedule",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Disable Dummy Disp Feed",
    model: "actual_dispense_feed_enable",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Delete Future Schedules",
    model: "delete_future_schedules",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Midnight Schedule Enable",
    model: "midnight_sch_enable",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Software Version",
    model: "software_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Hardware Version",
    model: "hardware_version",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Device UI Edit",
    model: "device_ui_edit",
    validationRules: "required"
  },
  {
    type: "select",
    inputType: "text",
    label: "Mode",
    model: "mode",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Mechanical Version",
    model: "mechanical_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Device Version",
    model: "device_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Group Settings",
    model: "group_settings",
    validationRules: { validFormat: { format: "JSON" } }
  },
  {
    type: "select",
    inputType: "text",
    label: "Feeder Type",
    model: "hopper_type",
    validationRules: ""
  }
].map(keyConfig => {
  return {
    ...keyConfig,
    property: keyConfig.model,
    getMethodParams: ({ activityLog }) => [
      activityLog,
      `data.${keyConfig.model}`
    ]
  };
});

export default pmSettingsConfig;
