/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pondMotherSchedules.js
Description: This file contains all the vuex store functions used in PondMother Schedules page
*/
// services
import ScheduleService from "@/services/ScheduleService";
import PondMotherService from "@/services/PondMotherService";
import FeedManagementService from "@/services/FeedManagementService";
// models
import PondTS from "@/model/pondTS";
import Pond from "@/model/pond.js";
import PondMotherTS from "@/model/pondMotherTS";
import PondMother from "@/model/pondmother";
import PmSchedule from "../../../model/pmSchedule";
import { cloneDeep } from "lodash";
import dateUtils from "@/utils/dateUtils";
// store and vuex modules
import store from "@/store";
import scheduleActions from "./scheduleActions";
// constants
import {
  DAY_TIME_IN_MS,
  DAY_TIME_IN_SECS,
  castSecsHHmmStr,
  naturalSort,
  timeStrHHmmVal
} from "@/utils/commonUtils";
import {
  INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS,
  OFFSET_BTW_TWO_TS_IN_MINS,
  POND_TS_STATUS,
  PM_TS_STATUS,
  OFFSET_FOR_LAST_COMMUNICATION_IN_MINS,
  POND_MODES,
  PM_MODES,
  TS_PM_MODES,
  dateQueryTypes,
  TS_CREATED_BY,
  MAX_AUTO_E_TIME_SECS,
  MAX_AUTO_S_TIME_SECS,
  MIN_TOTAL_FEED_VALUE_ST_MODE,
  MAX_TOTAL_FEED_VALUE_ST_MODE
} from "@/constants/schedule";
import ShrimpTalkService from "@/services/ShrimpTalkService";
import { alphaNumericComparator } from "../../../utils/commonUtils";
import PondsService from "../../../services/PondsService";
import ShrimpTalk from "../../../model/shrimptalk";
const cmCurrTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ;
const minTime = dateUtils.minTime;
const minTimeISO = dateUtils.minTimeISO;
const CREATED_BY = TS_CREATED_BY;
const INIT_STATE = {
  dateRange: [],
  selectedDay: cmCurrTimeSecs("UTC"),
  selectedPond: new Pond(),
  selectedPondSched: [],
  selectedPondPmDetails: [],
  selectedPondPmDetailsToPmId: {},
  selectedPondStDetails: [],
  selectedDayPmDetails: [],
  selectedDayStDetails: [],
  selectedPmIds: [],
  dayPmIdToTsIdToTs: {},
  dayPondIdToTsIdToTs: {},
  indexOnPmTSToPondTS: {},
  indexOnPondTSToGroupPmTS: {},
  expandedPmKeys: {},
  expandedPondKeys: [],
  pondCollapseTableData: {},
  pondIdToDtSecsToPmCollapseTableData: {},
  pondIdWithDetails: {},
  pondIdWithExistSchedDetails: {},
  stDetailsToStId: {},
  isSaveSchedForMultipleDays: false,
  userTimeZoneString: "UTC",
  dateQueryType: dateQueryTypes.TODAY,
  isPondListLoading: false,
  isPondSchedLoading: false,
  allPondsSchedStats: [],
  permissions: {},
  pmCountArray: [],
  feedingLevelValueWhenNoSchedule: []
};
export default {
  namespaced: true,
  modules: { scheduleActions },
  state: cloneDeep(INIT_STATE),
  getters: {
    getPermissions: function(state) {
      return state.permissions;
    },
    getArrOfDaysFromDateRange: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const dateRange = getters.getDateRange;
      const dayDiff = getters.getDateRangeDifference;
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const isoDate = `${dateRange[0] || "1970-01-01"}T00:00:00.000Z`;
      const s_date_secs = dateUtils
        .utcToZonedTime(
          dateUtils.castUserUTCToBrowserTZDate(isoDate, {
            timeZone: userTimeZoneString
          }),
          userTimeZoneString
        )
        .getTime();
      const limit = s_date_secs + dayDiff * DAY_TIME_IN_MS;
      const incrmntVal = DAY_TIME_IN_MS;
      const arrDays = [];
      for (let day = s_date_secs; day < limit; day += incrmntVal) {
        const formatDate = dateUtils.formatDate(
          day,
          "yyyy-MM-dd'T'00:00:00.000'Z'"
        );
        arrDays.push(
          dateUtils
            .utcToZonedTime(
              dateUtils.castUserUTCToBrowserTZDate(formatDate, {
                timeZone: userTimeZoneString
              }),
              userTimeZoneString
            )
            .getTime()
        );
      }
      return arrDays.sort();
    },
    getCurrUserLocation: function(state, getters, rootState, rootGetters) {
      return rootGetters["user/getCurrUserLocation"];
    },
    getDateRange: function(state, getters) {
      return state.dateRange;
    },
    getFeedingLevelValueWhenNoSchedule: function(state) {
      return state.feedingLevelValueWhenNoSchedule;
    },
    getDateQueryType: function(state) {
      return state.dateQueryType;
    },
    getDateRangeDifference: function(state, getters) {
      const dateRange = getters.getDateRange;
      if (dateRange.length) {
        return (
          dateUtils.differenceInDays(
            dateUtils.parse(dateRange[1], "yyyy-MM-dd", new Date()),
            dateUtils.parse(dateRange[0], "yyyy-MM-dd", new Date())
          ) + 1
        );
      }
      return 1;
    },
    getSelectedDay: function(state, getters) {
      return state.selectedDay;
    },
    getSelectedPond: function(state, getters) {
      return state.selectedPond;
    },
    getPondSchedLastUpdtStatus: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const pmSchedLastUpdtStatus = getters.getPmSchedLastUpdtStatus;
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      return (selectedDay, pondId) => {
        const pmIds = getters.getPmIdsToPondId[pondId] || [];
        return pmIds.reduce(
          (acc, currPmId) => {
            const statusCodeObj = pmSchedLastUpdtStatus(
              selectedDay,
              pondId,
              currPmId
            ) || {
              status_code: "NO_SCHEDULES",
              updated_iso_string: minTimeISO
            };
            acc[statusCodeObj.status_code]++;
            const newTime = statusCodeObj.updated_iso_string
              ? dateUtils.parseISO(statusCodeObj.updated_iso_string)
              : minTime;
            const accTime = acc.updated_iso_string
              ? dateUtils.parseISO(acc.updated_iso_string)
              : minTime;
            if (statusCodeObj.status_code === "NO_SCHEDULES") return acc;
            if (dateUtils.isAfter(accTime, newTime)) {
              acc.updated_iso_string = statusCodeObj.updated_iso_string;
            }
            return acc;
          },
          {
            NO_SCHEDULES: 0,
            SCHEDULES_NOT_UPDATED: 0,
            SCHEDULES_UPDATED: 0,
            updated_iso_string: dateUtils
              .getCurrDateInGivenTZ(userTimeZoneString)
              .toISOString()
          }
        );
      };
    },
    getPmSchedLastUpdtStatus: function(state, getters, rootState, rootGetters) {
      const pmCollapseDetails = getters.getPmCollapseTableData;
      const pmCommStatus = getters.getCommunicationStatusForSelectedPondDevices;
      const pmTSTableData = getters.getPmTSTableData;
      return (selectedDay, pondId, pmId) => {
        const key = selectedDay + "_" + pondId;
        const { updated_iso_string } = pmCommStatus[pmId] || {
          status_code: "OFF_LINE",
          updated_iso_string: minTimeISO
        };
        const currSched = pmCollapseDetails[key];
        const currPmSched = (currSched || {})[pmId];
        const responseObjGenerator = (status_code, updated_iso_string) => ({
          status_code,
          updated_iso_string
        });
        const schedulesLength = Object.values(
          pmTSTableData[key + "_" + pmId] || {}
        ).filter(x => x.bk_id).length;
        if (schedulesLength === 0) {
          return responseObjGenerator(
            "NO_SCHEDULES",
            updated_iso_string || minTimeISO
          );
        }
        if (currPmSched && currPmSched.modified) {
          const STATUS_IN_DEVICE = !currPmSched.modified.status;
          if (!STATUS_IN_DEVICE) {
            return responseObjGenerator("SCHEDULES_NOT_UPDATED", minTimeISO);
          }
        }
        return responseObjGenerator("SCHEDULES_UPDATED", updated_iso_string);
      };
    },
    getCommunicationStatusForSelectedPond: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const pmLastCommTime =
        getters.getCommunicationStatusForSelectedPondDevices;
      const setOfCommStatus = Object.keys(pmLastCommTime || {}).reduce(
        (acc, pmId) => {
          const status = pmLastCommTime[pmId];
          const statusCodeObj = acc[status.status_code];
          statusCodeObj.PM_COUNT++;
          const newTime = statusCodeObj.updated_iso_string
            ? dateUtils.parseISO(statusCodeObj.updated_iso_string)
            : minTime;
          const accTime = acc.updated_iso_string
            ? dateUtils.parseISO(acc.updated_iso_string)
            : minTime;
          if (dateUtils.isBefore(accTime, newTime)) {
            acc.updated_iso_string = statusCodeObj.updated_iso_string;
          }
          return acc;
        },
        {
          ON_LINE: { PM_COUNT: 0, updated_iso_string: minTimeISO },
          OFF_LINE: { PM_COUNT: 0, updated_iso_string: minTimeISO }
        }
      );
      return setOfCommStatus;
    },
    getCommunicationStatusForSelectedPondDevices: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const allPmDetails = state.selectedPondPmDetails;
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      return allPmDetails.reduce(
        (acc, { _id: pm_id, utc_last_communicated_at }) => {
          acc[pm_id] = {
            status_code: "OFF_LINE",
            updated_iso_string: minTimeISO
          };
          if (utc_last_communicated_at) {
            const currTime = dateUtils.getCurrDateInGivenTZ(
              userTimeZoneString || "UTC"
            );
            const pmUpdatedTime = dateUtils.castISOToGivenTZDate(
              utc_last_communicated_at,
              userTimeZoneString
            );
            acc[pm_id].updated_iso_string = dateUtils
              .castISOToGivenTZDate(
                utc_last_communicated_at,
                userTimeZoneString
              )
              .toISOString();
            if (
              dateUtils.differenceInMinutes(currTime, pmUpdatedTime) <=
              OFFSET_FOR_LAST_COMMUNICATION_IN_MINS
            ) {
              acc[pm_id].status_code = "ON_LINE";
            }
          }
          return acc;
        },
        {}
      );
    },
    getExistArrScheds: function(state, getters, rootState, rootGetters) {
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      return state.selectedPondSched
        .filter(sched => sched.status !== "DELETED")
        .map(sched => {
          const newSched = JSON.parse(JSON.stringify(sched));
          const date = dateUtils.utcToZonedTime(
            dateUtils.castUserUTCToBrowserTZDate(sched.date, {
              timeZone: userTimeZoneString
            }),
            userTimeZoneString
          );
          newSched.date_secs = date.getTime();
          newSched.time_slots.forEach(ts => {
            ts.pond_mothers.forEach(pm => {
              pm.feed_gap = +(pm.feed_gap / 60).toFixed(1);
            });
          });
          return newSched;
        });
    },
    getExistSchedDtSecsToPmIdToPmSchedDtls: function(state, getters) {
      const dtSecsToPmIdToPmSchedDtls = {};
      const objPmIdToPmTitle = Object.keys(
        getters.getSelectedPondPmDetailsToPmId
      ).reduce((acc, curr) => {
        acc[curr] = (
          getters.getSelectedPondPmDetailsToPmId[curr] || {
            title: undefined
          }
        ).title;
        return acc;
      }, {});
      getters.getExistArrScheds.forEach(sched => {
        const arrPmSchedule = PmSchedule.transformTSPMHierachyToPMTSHierachy(
          sched,
          objPmIdToPmTitle,
          "schedule"
        );
        console.log(
          "arrPmSchedule----",
          arrPmSchedule,
          getters.getExistArrScheds
        );
        arrPmSchedule.reduce((acc, currPm) => {
          const dayPondIdPmId = `${sched.date_secs}_${sched.pond_id}_${currPm.pond_mother_id}`;
          acc[dayPondIdPmId] = currPm;
          return acc;
        }, dtSecsToPmIdToPmSchedDtls);
      });
      return dtSecsToPmIdToPmSchedDtls;
    },
    getExistSchedDtSecsToPondIdToPondSchedDtls: function(state, getters) {
      const dtSecsToPondIdToPondSchedDtls = {};
      getters.getExistArrScheds.forEach(sched => {
        dtSecsToPondIdToPondSchedDtls[
          sched.date_secs + "_" + sched.pond_id
        ] = sched;
      });
      return dtSecsToPondIdToPondSchedDtls;
    },
    getExistSchedDtSecsToPondIdToPondSchedId: function(state, getters) {
      const dtSecsToPondIdToPondSchedId = {};
      getters.getExistArrScheds.forEach(sched => {
        dtSecsToPondIdToPondSchedId[sched.date_secs + "_" + sched.pond_id] =
          sched._id;
      });
      return dtSecsToPondIdToPondSchedId;
    },
    getExpandedPmKeysPondId: function(state, getters) {
      return state.expandedPmKeys;
    },
    getIsPondEditableStatus: function(state, getters, rootState, rootGetters) {
      return pondId => {
        const selectedDay = getters.getSelectedDay;
        const dayArrPondTS =
          getters.getPondTSTableData[selectedDay + "_" + pondId];
        const pmsLength = getters.getPmIdsToPondId[pondId].length;
        return Object.values(dayArrPondTS || {})
          .filter(
            x =>
              ![
                POND_TS_STATUS.STOPPED,
                POND_TS_STATUS.COMPLETED,
                POND_TS_STATUS.UI_COMPLETED
              ].includes(x.ui_status)
          )
          .every(ts => {
            return Object.keys(ts.pmsSubscribe || {}).length === pmsLength;
          });
      };
    },
    canEditAutomaticModePondTSForSelectedPond: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const pondId = getters.getSelectedPond._id;
      const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
      const selectedDay = getters.getSelectedDay;
      const dayArrPondTS =
        getters.getPondTSTableData[selectedDay + "_" + pondId];
      const pondMode = getters.getSelectedPondModeOnSelectedDate;
      let pmsLength = getters.getPmIdsToPondId[pondId].length;
      const response = {
        ST_NOT_YET_STARTED: false,
        SCHEDULE_MISMATCH: false
      };
      const currTimeSecs = dateUtils.getCurrTimeSecsInGivenTZ(
        userTimeZoneString
      );
      const stNotStartedYet = getters.getStsYetToStartAtGivenTime(currTimeSecs);
      const validSchedules = Object.values(dayArrPondTS || {}).filter(
        x =>
          ![
            POND_TS_STATUS.STOPPED,
            POND_TS_STATUS.COMPLETED,
            POND_TS_STATUS.UI_COMPLETED
          ].includes(x.ui_status)
      );
      response.SCHEDULE_MISMATCH = !validSchedules.every(ts => {
        if (pondMode === POND_MODES.AUTOMATIC) {
          const stPmIdsLength = [...ts.setStIds].reduce(
            (acc, x) =>
              acc + (getters.getSelectedPondStIdToPmIds[x] || []).length,
            0
          );
          pmsLength =
            pondMode === POND_MODES.AUTOMATIC ? stPmIdsLength : pmsLength;
        }
        return Object.keys(ts.pmsSubscribe || {}).length === pmsLength;
      });
      const stsHavingSchedules = validSchedules.reduce(
        (accStsHavingScheds, ts) => {
          if (pondMode === POND_MODES.AUTOMATIC) {
            accStsHavingScheds = new Set([
              ...ts.setStIds,
              ...accStsHavingScheds
            ]);
          }
          return accStsHavingScheds;
        },
        new Set()
      );
      response.ST_NOT_YET_STARTED =
        stNotStartedYet.filter(st => {
          return !stsHavingSchedules.has(st._id);
        }).length > 0;
      return response;
    },
    getIsSaveSchedForMultipleDays: function(state, getters) {
      return state.isSaveSchedForMultipleDays;
    },
    getIsPondListLoading: function(state) {
      return state.isPondListLoading;
    },
    getIsPondSchedLoading: function(state) {
      return state.isPondSchedLoading;
    },
    getPmCollapseTableData: function(state, getters) {
      return state.pondIdToDtSecsToPmCollapseTableData;
    },
    getAllPondsPmDetailsToPmId: function(state) {
      return Object.values(state.pondIdWithDetails).reduce((acc, pond) => {
        pond.pond_mothers.forEach(pm => (acc[pm._id] = pm));
        return acc;
      }, {});
    },
    getSelectedPondPmsDetails: function(state) {
      return state.selectedPondPmDetails;
    },
    getSelectedPondPmIds: function(state, getters) {
      return Object.keys(getters.getSelectedPondPmDetailsToPmId);
    },
    getSelectedPondStIds: function(state, getters) {
      return Object.keys(getters.getSelectedPondStDetailsToStId);
    },
    getSelectedPmIds(state) {
      return state.selectedPmIds;
    },
    getPondModeBySelectedPmIds(state, getters) {
      const modes = [
        ...new Set(
          getters.getSelectedPmIds
            .map(pmId => {
              console.log(
                "getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]",
                getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
              );
              return getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId];
            })
            // .map(x => (x ? x.managed_by : "NO_MODE"))
            .map(x => (x ? (x?.mode ? x.mode : x.managed_by) : "NO_MODE"))
        )
      ];
      console.log(
        "modes---",
        getters.getPondToPmsMapInSchedOnSelectedPondAndDate,
        modes
      );
      if (modes.length > 1) {
        return POND_MODES.MIXED;
      }
      return modes[0];
    },
    getPmIdsToPondId: function(state) {
      const pmIdsToPondId = {};
      Object.values(state.pondIdWithDetails).forEach(pond => {
        pmIdsToPondId[pond._id] = pond.pond_mothers.map(pm => pm._id);
      });
      return pmIdsToPondId;
    },
    getSelectedPondStIdToTimings: function(state, getters) {
      return Object.values(getters.getSelectedPondStDetailsToStId).reduce(
        (acc, currST) => {
          acc[currST._id] = {
            shrimp_talk_id: currST._id,
            s_time_in_seconds: MAX_AUTO_S_TIME_SECS,
            s_time: castSecsHHmmStr(MAX_AUTO_S_TIME_SECS),
            e_time_in_seconds: MAX_AUTO_E_TIME_SECS,
            e_time: castSecsHHmmStr(MAX_AUTO_E_TIME_SECS)
          };
          if (currST.settings) {
            if (!(currST.settings.start_time && currST.settings.end_time)) {
              console.log(currST);
              return acc;
            }
            acc[currST._id] = {
              shrimp_talk_id: currST._id,
              s_time: (currST.settings.start_time || "00:00:00").substr(0, 5),
              s_time_in_seconds: timeStrHHmmVal(currST.settings.start_time),
              e_time: (currST.settings.end_time || "22:00:00").substr(0, 5),
              e_time_in_seconds: timeStrHHmmVal(currST.settings.end_time)
            };
          }
          return acc;
        },
        {}
      );
    },
    getStsWorkingAtGivenTime: function(state, getters) {
      const sts = Object.values(getters.getSelectedPondStIdToTimings);
      const stDetailsToStId = getters.getSelectedPondStDetailsToStId;
      return timeSecs => {
        return sts
          .filter(
            x => x.s_time_in_seconds < timeSecs && x.e_time_in_second > timeSecs
          )
          .map(x => stDetailsToStId[x.shrimp_talk_id]);
      };
    },
    getStsYetToStartAtGivenTime: function(state, getters) {
      const sts = Object.values(getters.getSelectedPondStIdToTimings);
      const stDetailsToStId = getters.getSelectedPondStDetailsToStId;
      return timeSecs => {
        return sts
          .filter(x => x.s_time_in_seconds > timeSecs)
          .map(x => stDetailsToStId[x.shrimp_talk_id])
          .filter(x => x.pond_mothers.length > 0);
      };
    },
    getSelectedPondPmIdToStTimings: function(state, getters) {
      return Object.values(getters.getSelectedPondStDetailsToStId).reduce(
        (acc, currST) => {
          return currST.pond_mothers.reduce((accPm, currPm) => {
            accPm[currPm._id] = {
              shrimp_talk_id: currST._id,
              s_time_in_seconds: MAX_AUTO_S_TIME_SECS,
              s_time: castSecsHHmmStr(MAX_AUTO_S_TIME_SECS),
              e_time_in_seconds: MAX_AUTO_E_TIME_SECS,
              e_time: castSecsHHmmStr(MAX_AUTO_E_TIME_SECS)
            };
            if (
              currST.settings &&
              currST.settings.start_time &&
              currST.settings.end_time
            ) {
              accPm[currPm._id] = {
                shrimp_talk_id: currST._id,
                s_time: currST.settings.start_time.substr(0, 5),
                s_time_in_seconds: timeStrHHmmVal(currST.settings.start_time),
                e_time: currST.settings.end_time.substr(0, 5),
                e_time_in_seconds: timeStrHHmmVal(currST.settings.end_time)
              };
            }
            return accPm;
          }, acc);
        },
        {}
      );
    },
    getSelectedPondPmDetailsToPmId: function(state) {
      return state.selectedPondPmDetailsToPmId;
    },
    getSelectedDayPmDetails: function(state, getters) {
      return state.selectedDayPmDetails;
    },
    getSelectedPondStDetailsToStId: function(state) {
      return state.selectedPondStDetails.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    },
    getSelectedDayStDetailsToStId: function(state) {
      return state.selectedDayStDetails;
    },
    getRemainingTSToPondId: function(state, getters) {
      return (pondId, selectedDay) => {
        const pmIds = getters.getPmIdsToPondId[pondId] || [];
        const dayPmIdToTsIdToTs = getters.getPmTSTableData;
        const scheduleCountInPms = pmIds.map(pmId => {
          const pmKey = `${selectedDay}_${pondId}_${pmId}`;
          const arrPmTS = Object.values(dayPmIdToTsIdToTs[pmKey] || {});
          return arrPmTS
            .filter(x => ![PM_TS_STATUS.COMPLETED].includes(x.ui_status))
            .filter(
              x => !(x.ui_status === PM_TS_STATUS.STOPPED && x.SAVED_AT_DEVICE)
            ).length;
        });
        return Math.max(...scheduleCountInPms);
      };
    },
    getPmModeBasedOnDayPondId: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      return (selectedDay, pondId, pmId) => {
        const pmDetails = getters.getAllPondsPmDetailsToPmId[pmId] || {};
        let pmMode = pmDetails.managed_by || "NO_MODE";
        const scheduleMode = getters.getPmModeForSelectedPond(
          selectedDay,
          pondId,
          pmId
        );
        if (getters.isPastDay && scheduleMode !== "NO_MODE") {
          pmMode = scheduleMode;
        }
        return pmMode;
      };
    },
    getPmModeForSelectedPond: function(state, getters, rootState, rootGetters) {
      const dayExistPondSchedId =
        getters.getExistSchedDtSecsToPondIdToPondSchedId;
      const dayExistPmScheds = getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      return (selectedDay, pondId, pmId) => {
        const hasPondSchedules =
          dayExistPondSchedId[`${selectedDay}_${pondId}`];
        const pmDetails = getters.getSelectedPondPmDetailsToPmId[pmId] || {};
        let pmMode = pmDetails.managed_by || "NO_MODE";
        const isPmInSamePond = pmDetails.pond_id === pondId;
        const isPastDay = getters.isPastDay;
        const isCurrentDay = getters.isCurrentDay;
        if (
          hasPondSchedules &&
          (isPastDay || (isCurrentDay && !isPmInSamePond))
        ) {
          const hasPmSchedules =
            dayExistPmScheds[`${selectedDay}_${pondId}_${pmId}`];
          if (hasPmSchedules) {
            pmMode = hasPmSchedules.managed_by;
          }
        }
        return pmMode;
      };
    },
    getPmTSTableData: function(state, getters) {
      console.log("state.dayPmIdToTsIdToTs", state.dayPmIdToTsIdToTs);
      return state.dayPmIdToTsIdToTs;
    },
    getPondCollapseTableData: function(state, getters) {
      return Object.values(state.pondCollapseTableData).sort(
        naturalSort("title")
      );
    },
    getPondIdToSchedStats: function(state) {
      return state.allPondsSchedStats.reduce((acc, curr) => {
        acc[curr.pond_id] = curr;
        return acc;
      }, {});
    },
    getPondDetailsToPondId: function(state, getters) {
      const pondIdWithDetails = state.pondIdWithDetails;
      const pondIdWithSchedStats = getters.getPondIdToSchedStats;
      const defaultSchedStats = {
        feed: 0,
        dispensed_feed: 0,
        mode: undefined,
        feeding_level: "NORMAL"
      };
      return Object.keys(pondIdWithDetails)
        .map(pondId => {
          const schedStats = pondIdWithSchedStats[pondId];
          const pondDetails = pondIdWithDetails[pondId];
          if (schedStats) {
            const { feed, dispensed_feed, mode, feeding_level } = schedStats;
            return {
              ...pondDetails,
              feed,
              dispensed_feed,
              mode,
              feeding_level
            };
          }
          return { ...pondDetails, ...defaultSchedStats };
        })
        .reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});
    },
    getPondDetailsToPondIdHavingPms: function(state, getters) {
      let pondDetailsToPondId = {};
      pondDetailsToPondId = Object.assign(
        {},
        pondDetailsToPondId,
        getters.getPondDetailsToPondId
      );
      Object.keys(pondDetailsToPondId).forEach(pondId => {
        if (pondDetailsToPondId[pondId].pond_mothers.length === 0) {
          delete pondDetailsToPondId[pondId];
        }
      });
      return pondDetailsToPondId;
    },
    getPmCount: function(state, getters) {
      return state.pmCountArray;
    },
    getPondModeByPmDetailsInPond: function(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const pmModeBasedOnDayPondId = getters.getPmModeBasedOnDayPondId;
      const getterPondToPmsIdsOnDay = getters.getPondToPmIdsMapInSched;
      const selectedDay = getters.getSelectedDay;
      return pondId => {
        const pondToPmIds = getterPondToPmsIdsOnDay(pondId);
        const mode = [];
        (pondToPmIds || []).forEach(pmId => {
          mode.push(pmModeBasedOnDayPondId(selectedDay, pondId, pmId));
        });
        const pondMode = [...new Set(mode)];
        if (pondMode.length === 1) {
          return pondMode[0];
        }
        return POND_MODES.MIXED; // POND_MODES.HYBRID got changed to mixed due to new mode changes
      };
    },
    getSelectedPondModeOnSelectedDate: function(state, getters) {
      const getterPondToPmsOnDay =
        getters.getPondToPmsMapInSchedOnSelectedPondAndDate;
      const modeArr = Object.values(getterPondToPmsOnDay).map(
        x => x.managed_by
      );
      const pondMode = [...new Set(modeArr)];
      if (pondMode.length === 1) {
        return pondMode[0];
      }
      return POND_MODES.MIXED; // POND_MODES.HYBRID got changed to mixed due to new mode changes
    },
    getPondToPmsMapInSchedByDate: function(state, getters) {
      const dayPondIdPmIdScheds =
        getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      const dayPondIdPmIdKeys = Object.keys(dayPondIdPmIdScheds);
      const pmIdToPmDetails = getters.getSelectedPondPmDetailsToPmId;
      const dayPondIdSchedId = getters.getExistSchedDtSecsToPondIdToPondSchedId;
      const pondId = getters.getSelectedPond._id;
      return selectedDay => {
        const isPondHasSched =
          Object.values(dayPondIdSchedId[selectedDay + "_" + pondId] || {})
            .length > 0;
        let schedulePms = pmIdToPmDetails;
        const isPastDay = getters.isPastDay;
        if (isPondHasSched && isPastDay) {
          const objAccPms = dayPondIdPmIdKeys.reduce(
            (acc, key) => {
              const iskeyContainDayPond = key.includes(
                `${selectedDay}_${pondId}`
              );
              if (iskeyContainDayPond) {
                const nPm = new PondMother();
                nPm._id = dayPondIdPmIdScheds[key].pond_mother_id;
                nPm.pond_mother_id = dayPondIdPmIdScheds[key].pond_mother_id;
                nPm.code = (
                  pmIdToPmDetails[nPm._id] || dayPondIdPmIdScheds[key]
                ).code;
                nPm.setPondMotherTitle = (
                  pmIdToPmDetails[nPm._id] || dayPondIdPmIdScheds[key]
                ).title;
                acc.pmRecords[nPm._id] = nPm;
                acc.uniquePmIds.add(nPm._id);
              }
              return acc;
            },
            {
              uniquePmIds: new Set(),
              pmRecords: {}
            }
          );
          schedulePms = objAccPms.pmRecords;
        }
        return schedulePms;
      };
    },
    getPondToPmsMapInSchedOnSelectedPondAndDate: function(state, getters) {
      // getting data based on schedules api
      const dayPondIdPmIdScheds =
        getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      // getting data from pm-device-mapping-log
      const selectedDaysPms = getters.getSelectedDayPmDetails;
      // getting data from pond mother's api
      const pmIdToPmDetails = getters.getSelectedPondPmDetailsToPmId;
      // getting data based on schedules api
      const dayPondIdSchedId = getters.getExistSchedDtSecsToPondIdToPondSchedId;
      const selectedDay = getters.getSelectedDay;
      const pondId = getters.getSelectedPond._id;
      const scheduleFeedingLevel = cloneDeep(
        state.feedingLevelValueWhenNoSchedule
      );
      const isPondHasSched =
        Object.values(dayPondIdSchedId[selectedDay + "_" + pondId] || {})
          .length > 0;
      let schedulePms = pmIdToPmDetails;
      const schedulePmsCopy = cloneDeep(schedulePms);
      const isPastDay = getters.isPastDay;
      // if (isPondHasSched && isPastDay) {
      //   const arrPms = selectedDaysPms.map(pm => {
      //     const tempPm = cloneDeep(pm);
      //     const key = `${selectedDay}_${pondId}_${pm._id}`;
      //     if (dayPondIdPmIdScheds[key] && isPastDay) {
      //       tempPm.managed_by = dayPondIdPmIdScheds[key].managed_by;
      //     }
      //     return tempPm;
      //   });
      //   schedulePms = arrPms.reduce((acc, pm) => {
      //     acc[pm._id] = pm;
      //     return acc;
      //   }, {});
      // }
      // New logic for past day
      if (isPastDay) {
        const arrPms = selectedDaysPms.map(pm => {
          const tempPm = cloneDeep(pm);
          if (isPondHasSched) {
            const key = `${selectedDay}_${pondId}_${pm._id}`;
            if (dayPondIdPmIdScheds[key]) {
              tempPm.managed_by =
                tempPm.mode === "HYBRID"
                  ? "HYBRID"
                  : dayPondIdPmIdScheds[key].managed_by;
              tempPm.feeding_level = dayPondIdPmIdScheds[key].feeding_level;
            }
          } else {
            // to do
          }
          return tempPm;
        });
        schedulePms = arrPms.reduce((acc, pm) => {
          acc[pm._id] = pm;
          return acc;
        }, {});
        Object.keys(schedulePms).forEach(pmId => {
          schedulePms[pmId].feeding_level =
            scheduleFeedingLevel[0].pm_feeding_level[pmId];
        });
      } else {
        Object.keys(schedulePmsCopy).forEach(pmId => {
          schedulePmsCopy[pmId].feeding_level =
            scheduleFeedingLevel[0].pm_feeding_level[pmId];
        });
        schedulePms = schedulePmsCopy;
      }
      return schedulePms;
    },
    getPondToPmIdsMapInSched: function(state, getters) {
      const pondIdPms = getters.getPmIdsToPondId;
      const dayPondIdPmIdScheds =
        getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      const dayPondIdPmIdKeys = Object.keys(dayPondIdPmIdScheds);
      const pmIdToPmDetails = getters.getSelectedPondPmDetailsToPmId;
      const dayPondIdSchedId = getters.getExistSchedDtSecsToPondIdToPondSchedId;
      const selectedDay = getters.getSelectedDay;
      return pondId => {
        const isPondHasSched =
          Object.values(dayPondIdSchedId[selectedDay + "_" + pondId] || {})
            .length > 0;
        let pmIds = pondIdPms[pondId];
        const isPastDay = getters.isPastDay;
        if (isPondHasSched && isPastDay) {
          const setPmIds = dayPondIdPmIdKeys.reduce((acc, key) => {
            const iskeyContainDayPond = key.includes(
              `${selectedDay}_${pondId}`
            );

            if (iskeyContainDayPond) {
              const pmId = dayPondIdPmIdScheds[key].pond_mother_id;
              acc.add(pmId);
            }
            return acc;
          }, new Set());
          pmIds = [...setPmIds];
        }
        const getPmTitle = pmId => {
          const pmObj = pmIdToPmDetails[pmId] || { code: "NO_CODE" };
          return pmObj.title || pmObj.code;
        };
        return pmIds.sort((aPmId, bPmId) =>
          alphaNumericComparator(getPmTitle(aPmId), getPmTitle(bPmId))
        );
      };
    },
    getPondTSTableData: function(state, getters) {
      return state.dayPondIdToTsIdToTs;
    },
    getStDetailsToStId: function(state) {
      return state.stDetailsToStId;
    },
    getSelectedPondStIdsToPmIds: function(state, getters) {
      const pmIdsToStIds = {};
      const pmDetailsToPmId = getters.getSelectedPondPmDetailsToPmId;
      Object.keys(pmDetailsToPmId).forEach(pmId => {
        const pmDetails = pmDetailsToPmId[pmId];
        if (
          pmDetails &&
          (pmDetails.managed_by === PM_MODES.AUTOMATIC ||
            pmDetails.managed_by === "HYBRID")
        ) {
          pmIdsToStIds[pmId] = pmDetails?.shrimp_talk_id;
        }
      });
      return pmIdsToStIds;
    },
    getSelectedPondStIdToPmIds: function(state, getters) {
      const stIdsToStDetails = getters.getSelectedPondStDetailsToStId;
      return Object.values(stIdsToStDetails).reduce((acc, curr) => {
        acc[curr._id] = curr.pond_mothers.map(x => x._id);
        return acc;
      }, {});
    },
    isCurrentDay: function(state, getters) {
      return getters.getDateQueryType === dateQueryTypes.TODAY;
    },
    isPastDay: function(state, getters) {
      return getters.getDateQueryType === dateQueryTypes.PAST;
    },
    isFutureDay: function(state, getters) {
      return getters.getDateQueryType === dateQueryTypes.FUTURE;
    }
  },
  mutations: {
    ADD_PM_TS_TO_PM_TS_TABLE_DATA_AND_POND_TS_TABLE: function(
      state,
      { selectedDay, pmId, pondId, siblingTSId, stTimings, pm_mode }
    ) {
      const userTimeZoneString = state.userTimeZoneString;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;
      const pmDetails = state.selectedPondPmDetailsToPmId[pmId];
      let presentPmTS = new PondMotherTS(
        pmDetails._id,
        pmDetails.code,
        pmDetails.settings.kg_dispense_time_sec,
        pmDetails.managed_by,
        CREATED_BY.IT_SELF
      );
      const dateQueryType = state.dateQueryType;
      const isCurrentDay = dateQueryType === dateQueryTypes.TODAY;
      if (siblingTSId) {
        presentPmTS =
          state.dayPmIdToTsIdToTs[`${selectedDay}_${pondId}_${pmId}`][
            siblingTSId
          ];
      }
      const pond_mother_code = presentPmTS.pond_mother_code;
      const pond_mother_id = presentPmTS.pond_mother_id;
      const kg_dispense_time_sec = presentPmTS.kg_dispense_time_sec;
      const mode = pm_mode === "HYBRID" ? "HYBRID" : presentPmTS.mode;
      const pondTsObj = new PondTS(pondId, mode, CREATED_BY.POND_MOTHER);
      const pmTS = new PondMotherTS(
        pond_mother_id,
        pond_mother_code,
        kg_dispense_time_sec,
        mode,
        CREATED_BY.IT_SELF
      ); // linking pond and pm
      indexOnPmTSToPondTS[pmTS.ui_id] = pondTsObj.ui_id;
      pondTsObj.pmsSubscribe[pond_mother_id] = pmTS.ui_id;
      indexOnPondTSToGroupPmTS[pondTsObj.ui_id] = pondTsObj.pmsSubscribe;
      pmTS.dateQueryType = dateQueryType; // assigning previous ts values
      if (mode === PM_MODES.AUTOMATIC) {
        pmTS.shrimp_talk_id = stTimings.shrimp_talk_id;
        pondTsObj.setStIds.add(stTimings.shrimp_talk_id);
      }
      let from_time_secs = siblingTSId
        ? presentPmTS.e_time_secs
        : presentPmTS.s_time_secs;
      if (!siblingTSId && mode === PM_MODES.AUTOMATIC) {
        from_time_secs = stTimings.s_time_in_seconds;
      }
      const ocf = presentPmTS.ocf;
      const feed_gap = 1;
      const currTimeInSecs = cmCurrTimeSecs(userTimeZoneString);

      if (isCurrentDay && from_time_secs < currTimeInSecs) {
        from_time_secs =
          currTimeInSecs + INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS * 60;
      } else if (siblingTSId) {
        from_time_secs += OFFSET_BTW_TWO_TS_IN_MINS * 60;
      }
      from_time_secs = from_time_secs % DAY_TIME_IN_SECS;
      pondTsObj.dateQueryType = dateQueryType;
      pondTsObj.from_time = {
        eventType: "INIT",
        updtPropVal: castSecsHHmmStr(from_time_secs)
      };
      if (mode === PM_MODES.AUTOMATIC) {
        pondTsObj.to_time = {
          eventType: "INIT",
          updtPropVal: stTimings.e_time
        };
      }
      pondTsObj.ocf = ocf;
      pondTsObj.feed_gap = feed_gap;
      pondTsObj.total_feed_kgs = {
        updtPropVal: 0,
        eventType: "INIT"
      };
      pondTsObj.is_user_edited = true;
      pmTS.from_time = {
        eventType: "INIT",
        updtPropVal: castSecsHHmmStr(from_time_secs)
      };
      if (mode === PM_MODES.AUTOMATIC) {
        pmTS.to_time = { eventType: "INIT", updtPropVal: stTimings.e_time };
      }
      pmTS.ocf = ocf;
      pmTS.feed_gap = feed_gap;
      pmTS.total_feed_kgs = {
        updtPropVal: 0,
        eventType: "INIT"
      };
      if (pm_mode === "HYBRID") {
        pmTS.mode_g = {
          updtPropVal: "HYBRID",
          eventType: "INIT"
        };
        pmTS.feeding_level_g = {
          updtPropVal: 0,
          eventType: "INIT"
        };
      }
      state.dayPmIdToTsIdToTs[`${selectedDay}_${pondId}_${pmId}`][
        pmTS.ui_id
      ] = pmTS;
      pondTsObj.refresh(selectedDay, state.dayPmIdToTsIdToTs);
      state.dayPondIdToTsIdToTs[selectedDay + "_" + pondId][
        pondTsObj.ui_id
      ] = pondTsObj;
      state.dayPondIdToTsIdToTs = Object.assign({}, state.dayPondIdToTsIdToTs);
      state.dayPmIdToTsIdToTs = Object.assign({}, state.dayPmIdToTsIdToTs);
      state.indexOnPmTSToPondTS = Object.assign({}, state.indexOnPmTSToPondTS);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        state.indexOnPondTSToGroupPmTS
      );
    },
    ADD_POND_TS_TO_POND_TS_TABLE_DATA_AND_PM_TS_TABLE: function(
      state,
      { pondId, pmsObj, selectedDay, siblingTSId, mode, pmStTimings }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const currDayKey = selectedDay + "_" + pondId;
      const userTimeZoneString = state.userTimeZoneString;
      const dateQueryType = state.dateQueryType;
      const isCurrentDay = dateQueryType === dateQueryTypes.TODAY;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;

      let presentPondTS = new PondTS(pondId, mode, CREATED_BY.IT_SELF);

      if (siblingTSId) {
        presentPondTS = pondTSTableData[currDayKey][siblingTSId];
      }

      const pondTSObj = new PondTS(
        pondId,
        presentPondTS.mode,
        CREATED_BY.IT_SELF
      );
      let from_time_secs = presentPondTS.e_time_secs;
      const ocf = presentPondTS.ocf;
      const feed_gap = 1; // presentPondTS.feed_gap;
      const currTimeInSecs = cmCurrTimeSecs(userTimeZoneString); // from_time_secs less than currTimesecs condition is for schedule if all ts are completed then the new timeslot should be taken from the current time

      if (isCurrentDay && from_time_secs < currTimeInSecs) {
        from_time_secs =
          currTimeInSecs + INIT_TS_OFFSET_FROM_CURR_TIME_IN_MINS * 60;
      } else {
        from_time_secs += OFFSET_BTW_TWO_TS_IN_MINS * 60;
      }
      from_time_secs = from_time_secs % DAY_TIME_IN_SECS;
      const pmsKeys = Object.keys(pmsObj);
      pondTSObj.from_time = {
        eventType: "init",
        updtPropVal: castSecsHHmmStr(from_time_secs)
      };
      pondTSObj.dateQueryType = dateQueryType;
      pondTSObj.total_feed_kgs = {
        updtPropVal: 0,
        eventType: "INIT"
      };
      if (mode === "HYBRID") {
        pondTSObj.mode_g = {
          updtPropVal: "HYBRID",
          eventType: "INIT"
        };
        pondTSObj.feeding_level_g = {
          updtPropVal: 0,
          eventType: "INIT"
        };
      }
      pmsKeys.forEach(pmId => {
        const tsObj = new PondMotherTS(
          pmId,
          pmsObj[pmId].code,
          pmsObj[pmId].settings.kg_dispense_time_sec,
          mode, // pmsObj[pmId].managed_by,
          CREATED_BY.POND
        );
        tsObj.s_time = pondTSObj.s_time;
        tsObj.s_time_secs = pondTSObj.s_time_secs;
        tsObj.dateQueryType = dateQueryType;
        tsObj.feed = 0;
        tsObj.ocf = ocf;
        tsObj.feeding_level = 0;
        tsObj.feed_gap = feed_gap;
        tsObj.is_user_edited = true;
        tsObj.updtToTime("total_feed_kgs", "INIT");
        indexOnPmTSToPondTS[tsObj.ui_id] = pondTSObj.ui_id;
        pondTSObj.pmsSubscribe[pmId] = tsObj.ui_id;
        pmTSTableData[`${selectedDay}_${pondId}_${pmId}`][tsObj.ui_id] = tsObj;
      });
      indexOnPondTSToGroupPmTS[pondTSObj.ui_id] = pondTSObj.pmsSubscribe;
      pondTSObj.is_user_edited = true;
      pondTSObj.refresh(selectedDay, pmTSTableData);
      pondTSTableData[currDayKey][pondTSObj.ui_id] = pondTSObj;
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        indexOnPondTSToGroupPmTS
      );
    },
    CHANGE_IS_SAVE_SCHED_FOR_MULTIPLE_DAYS: function(
      state,
      isSaveSchedForMultipleDays
    ) {
      state.isSaveSchedForMultipleDays = isSaveSchedForMultipleDays;
    },
    CHECK_VALID_AUTOMATIC_MODE_PM_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId, pmId }
    ) {
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const currDayPmTS = pmTSTableData[`${selectedDay}_${pondId}_${pmId}`];
      const validTimeSlots = PondMotherTS.getAllValidPmTSFromArrPmTS(
        cloneDeep(Object.values(currDayPmTS))
      );

      if (validTimeSlots.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }

      const errors = [];

      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };

      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];

        try {
          const error = currTS.isValidTotalFeedForAutomaticMode();
          insertErrors(error, currTS.ui_id);
        } catch (errMsg) {
          console.error(errMsg);
        }
      }

      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CHECK_VALID_AUTOMATIC_MODE_POND_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const pmDetailsToPmId = state.selectedPondPmDetailsToPmId;
      const userTimeZoneString = state.userTimeZoneString;
      const currDayPondTS = pondTSTableData[`${selectedDay}_${pondId}`];
      const validTimeSlots = PondTS.getAllValidPondTSFromArrPondTS(
        cloneDeep(Object.values(currDayPondTS))
      );

      if (validTimeSlots.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }

      const errors = [];

      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };

      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        const error = currTS.isValidTotalFeedForAutomaticMode(
          userTimeZoneString
        );
        insertErrors(error, currTS.ui_id);

        if (errors.length === 0) {
          const allPmTS = currTS.getAllPmTSFromPmTSObj(
            selectedDay,
            pmTSTableData
          );

          const insertPmErrorsToPondErrors = (tfError, pmTitle) => {
            if (!["NO_ERROR", "SKIP_ERROR"].includes(tfError)) {
              tfError.validation_flow = "pond_to_pm";
              tfError.pm_title = pmTitle;
              insertErrors(tfError, currTS.ui_id);
            }
          };

          allPmTS.forEach(pmTS => {
            const pmTitle =
              pmDetailsToPmId[pmTS.pond_mother_id].title ||
              pmTS.pond_mother_code ||
              "NO_TITLE";
            const tfError = pmTS.isValidTotalFeedForAutomaticMode(
              userTimeZoneString
            );
            insertPmErrorsToPondErrors(tfError, pmTitle);
          });
        }
      }

      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CHECK_VALID_BASIC_MODE_PM_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId, pmId }
    ) {
      // const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const userTimeZoneString = state.userTimeZoneString;
      const currDayPmTS = pmTSTableData[`${selectedDay}_${pondId}_${pmId}`];
      const validTimeSlots = PondMotherTS.getAllValidPmTSFromArrPmTS(
        cloneDeep(Object.values(currDayPmTS))
      );

      if (validTimeSlots.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }

      const errors = [];

      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };

      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        let error = currTS.isValidTotalFeedForBasicMode(userTimeZoneString);
        insertErrors(error, currTS.ui_id);
        error = currTS.isValidOCFForBasicMode(userTimeZoneString);
        insertErrors(error, currTS.ui_id);
      }

      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CHECK_VALID_BASIC_MODE_POND_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const pmDetailsToPmId = state.selectedPondPmDetailsToPmId;
      const userTimeZoneString = state.userTimeZoneString;
      const currDayPondTS = pondTSTableData[`${selectedDay}_${pondId}`];
      const validTimeSlots = PondTS.getAllValidPondTSFromArrPondTS(
        cloneDeep(Object.values(currDayPondTS))
      );

      if (validTimeSlots.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }

      const errors = [];

      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };

      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        let error;
        error = currTS.isValidTotalFeedForBasicMode(userTimeZoneString);
        insertErrors(error, currTS.ui_id);
        error = currTS.isValidOCFForBasicMode(userTimeZoneString);
        insertErrors(error, currTS.ui_id);

        if (errors.length === 0) {
          const allPmTS = currTS.getAllPmTSFromPmTSObj(
            selectedDay,
            pmTSTableData
          );

          const insertPmErrorsToPondErrors = (tfError, pmTitle) => {
            if (!["NO_ERROR", "SKIP_ERROR"].includes(tfError)) {
              tfError.validation_flow = "pond_to_pm";
              tfError.pm_title = pmTitle;
              insertErrors(tfError, currTS.ui_id);
            }
          };

          allPmTS.forEach(pmTS => {
            const pmTitle =
              pmDetailsToPmId[pmTS.pond_mother_id].title ||
              pmTS.pond_mother_code ||
              "NO_TITLE";
            const tfError = pmTS.isValidTotalFeedForBasicMode(
              userTimeZoneString
            );
            insertPmErrorsToPondErrors(tfError, pmTitle);
            const ocfError = pmTS.isValidOCFForBasicMode(userTimeZoneString);
            insertPmErrorsToPondErrors(ocfError, pmTitle);
          });
        }
      }

      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CHECK_VALID_SCHEDULE_MODE_PM_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId, pmId, deletedTSBkIds, stId }
    ) {
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const userTimeZoneString = state.userTimeZoneString;
      const currDayPmTS = pmTSTableData[`${selectedDay}_${pondId}_${pmId}`];
      const validTimeSlots = PondMotherTS.getAllValidPmTSFromArrPmTS(
        cloneDeep(Object.values(currDayPmTS))
      );
      if (validTimeSlots.length === 0 && deletedTSBkIds.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }
      let errors = [];
      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };
      const tsWithBasicTimeErrors = [];
      const insertTimeErrorTSId = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          tsWithBasicTimeErrors.push(schedule_id);
        }
      };
      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        const error = currTS.isValidScheduleTIntvl(
          userTimeZoneString,
          "change"
        );
        insertErrors(error, currTS.ui_id);
        insertTimeErrorTSId(error, currTS.ui_id);
      }
      const timeSlotsToValidateByTime = PondMotherTS.getAllValidPmTSFromArrPmTSByTime(
        cloneDeep(Object.values(currDayPmTS))
      );
      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        let error;
        if (tsWithBasicTimeErrors.length === 0) {
          error = currTS.isTSOverlapping(timeSlotsToValidateByTime);
          insertErrors(error, currTS.ui_id);
        }

        if (currTS.mode !== "SHRIMP_TALK" && currTS.mode !== "AUTOMATIC") {
          error = currTS.isValidOCFForScheduleMode(userTimeZoneString);
          insertErrors(error, currTS.ui_id);
        }
        if (currTS.mode === "SHRIMP_TALK" || currTS.mode === "AUTOMATIC") {
          //  currTS.isValidOCFForScheduleMode(userTimeZoneString);
          if (currTS.feed <= MIN_TOTAL_FEED_VALUE_ST_MODE) {
            error = {
              type: "feed",
              validation: "minimum",
              threshold: MIN_TOTAL_FEED_VALUE_ST_MODE
            };
            insertErrors(error, currTS.ui_id);
          } else if (currTS.feed > MAX_TOTAL_FEED_VALUE_ST_MODE) {
            error = {
              type: "feed",
              validation: "maximum",
              threshold: MAX_TOTAL_FEED_VALUE_ST_MODE
            };
            insertErrors(error, currTS.ui_id);
          }
        } else {
          error = currTS.isValidTotalFeedForScheduleMode();
          insertErrors(error, currTS.ui_id);
        }
        if (
          (currTS.mode === "SHRIMP_TALK" || currTS.mode === "AUTOMATIC") &&
          stId === undefined
        ) {
          error = {
            type: "stCheckForHybridMode"
          };
          insertErrors(error, currTS.ui_id);
        }
        if (currTS.mode === "HYBRID") {
          error = {
            type: "modeChack",
            fieldName: "Mode"
          };
          insertErrors(error, currTS.ui_id);
        }
      }
      errors = errors.filter(err => err.error !== undefined);
      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CHECK_VALID_SCHEDULE_MODE_POND_DATA_TO_SAVE: function(
      state,
      { selectedDay, pondId, deletedTSBkIds, getSTId }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const pmDetailsToPmId = state.selectedPondPmDetailsToPmId;
      const userTimeZoneString = state.userTimeZoneString;
      const currDayPondTS = pondTSTableData[`${selectedDay}_${pondId}`];
      const validTimeSlots = PondTS.getAllValidPondTSFromArrPondTS(
        cloneDeep(Object.values(currDayPondTS))
      );
      if (validTimeSlots.length === 0 && deletedTSBkIds.length === 0) {
        throw {
          type: "FAIL_SAVE",
          validation_type: "NO_FIELD_CHANGED"
        };
      }
      let errors = [];
      const errorTypes = [];
      const insertErrors = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          errors.push({
            schedule_id,
            error
          });
        }
      };

      const tsWithBasicTimeErrors = [];

      const insertTimeErrorTSId = (error, schedule_id) => {
        if (!["NO_ERROR", "SKIP_ERROR"].includes(error)) {
          tsWithBasicTimeErrors.push(schedule_id);
        }
      };

      const getAllPmTS = currTS => {
        const pmsSubscribe = currTS.pmsSubscribe;
        const pmIds = Object.keys(pmsSubscribe);
        const allPmTS = pmIds.map(pmId => {
          const pmTSKey = `${selectedDay}_${pondId}_${pmId}`;
          return pmTSTableData[pmTSKey][pmsSubscribe[pmId]];
        });
        return allPmTS;
      };

      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        const error = currTS.isValidScheduleTIntvl(
          userTimeZoneString,
          "change"
        );
        insertErrors(error, currTS.ui_id);
        insertTimeErrorTSId(error, currTS.ui_id);
      }
      const timeSlotsToValidateByTime = PondTS.getAllValidPondTSFromArrPondTSByTime(
        cloneDeep(Object.values(currDayPondTS))
      );
      for (let i = 0; i < validTimeSlots.length; i++) {
        const currTS = validTimeSlots[i];
        let error;

        if (tsWithBasicTimeErrors.length === 0) {
          error = currTS.isTSOverlapping(timeSlotsToValidateByTime);
          insertErrors(error, currTS.ui_id);
        }

        if (currTS.mode !== "SHRIMP_TALK" && currTS.mode !== "AUTOMATIC") {
          error = currTS.isValidOCFForScheduleMode(userTimeZoneString);
          insertErrors(error, currTS.ui_id);
        }
        if (currTS.mode === "SHRIMP_TALK" || currTS.mode === "AUTOMATIC") {
          //  currTS.isValidOCFForScheduleMode(userTimeZoneString);
          if (
            currTS.feed <=
            getAllPmTS(currTS).length * MIN_TOTAL_FEED_VALUE_ST_MODE
          ) {
            error = {
              type: "feed",
              validation: "minimum",
              threshold:
                getAllPmTS(currTS).length * MIN_TOTAL_FEED_VALUE_ST_MODE
            };
            // if (!errorTypes.includes(error.type)) {
            insertErrors(error, currTS.ui_id);
            //   errorTypes.push(error.type);
            // }
          } else if (
            currTS.feed >
            getAllPmTS(currTS).length * MAX_TOTAL_FEED_VALUE_ST_MODE
          ) {
            error = {
              type: "feed",
              validation: "maximum",
              threshold: MAX_TOTAL_FEED_VALUE_ST_MODE
            };
            // if (!errorTypes.includes(error.type)) {
            insertErrors(error, currTS.ui_id);
            //   errorTypes.push(error.type);
            // }
          }
        } else {
          error = currTS.isValidTotalFeedForScheduleMode();
          insertErrors(error, currTS.ui_id);
        }
        if (currTS.mode === "HYBRID") {
          error = {
            type: "modeChack",
            fieldName: "Mode"
          };
          if (!errorTypes.includes(error.type)) {
            errors.push({
              error,
              schedule_id: currTS.ui_id
            });
            errorTypes.push(error.type);
          }
          // insertErrors(error, currTS.ui_id);
        }
        if (errors.length === 0) {
          const allPmTS = getAllPmTS(currTS);
          allPmTS.forEach(pmTS => {
            const pmTitle =
              pmDetailsToPmId[pmTS.pond_mother_id].title ||
              pmTS.pond_mother_code ||
              "NO_TITLE";
            const tfError = pmTS.isValidTotalFeedForScheduleMode(
              userTimeZoneString
            );

            if (!["NO_ERROR", "SKIP_ERROR"].includes(tfError)) {
              tfError.validation_flow = "pond_to_pm";
              tfError.pm_title = pmTitle;
              insertErrors(tfError, currTS.ui_id);
            }
            if (currTS.mode === "SHRIMP_TALK" || currTS.mode === "AUTOMATIC") {
              const pmIds = Object.keys(currTS.pmsSubscribe);
              pmIds.map(pmId => {
                if (getSTId[pmId] === undefined) {
                  const error = {
                    type: "stCheckForHybridMode",
                    code: pmDetailsToPmId[pmId].code
                  };
                  const key = `${error.type}-${error.code}`;
                  if (!errorTypes.includes(key)) {
                    errors.push({
                      error,
                      schedule_id: currTS.ui_id,
                      pmData: pmDetailsToPmId[pmId]
                    });
                    errorTypes.push(key);
                  }
                }
              });
            } else {
              const ocfError = pmTS.isValidOCFForScheduleMode(
                userTimeZoneString
              );

              if (!["NO_ERROR", "SKIP_ERROR"].includes(ocfError)) {
                ocfError.validation_flow = "pond_to_pm";
                ocfError.pm_title = pmTitle;
                insertErrors(ocfError, currTS.ui_id);
              }
            }
          });
        }
      }
      errors = errors.filter(err => err.error !== undefined);
      if (errors.length > 0) {
        throw {
          type: "FAIL_SAVE",
          errors
        };
      }
    },
    CLEAR_POND_TS_TABLE_DATA: function(state, { pondId, pmId, days }) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      days.forEach(day => {
        const pondTSIds = Object.keys(
          pondTSTableData[day + "_" + pondId] || {}
        );
        pondTSIds.forEach(pondTSId => {
          delete indexOnPondTSToGroupPmTS[pondTSId];
        });
        delete pondTSTableData[day + "_" + pondId];
        if (Array.isArray(pmId)) {
          pmId.forEach(pmId => {
            const key = `${day}_${pondId}_${pmId}`;
            const pmTSIds = Object.keys(pmTSTableData[key] || {});
            pmTSIds.forEach(pmTSId => {
              delete indexOnPmTSToPondTS[pmTSId];
            });
            delete pmTSTableData[key];
          });
        } else {
          const key = `${day}_${pondId}_${pmId}`;
          const pmTSIds = Object.keys(pmTSTableData[key] || {});
          pmTSIds.forEach(pmTSId => {
            delete indexOnPmTSToPondTS[pmTSId];
          });
          delete pmTSTableData[key];
        }
      });
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        indexOnPondTSToGroupPmTS
      );
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
    },
    DELETE_PM_TS_FROM_POND_TS: function(
      state,
      { selectedDay, pondId, pmId, pmTSId }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;
      if (pondTSTableData && pmTSTableData) {
        const dayPondIdPmIdKey = `${selectedDay}_${pondId}_${pmId}`;
        if (
          pondTSTableData[selectedDay + "_" + pondId] &&
          pmTSTableData[dayPondIdPmIdKey]
        ) {
          const pmTS = pmTSTableData[dayPondIdPmIdKey][pmTSId];
          const pondTSId = indexOnPmTSToPondTS[pmTS.ui_id];
          const pondTS = pondTSTableData[selectedDay + "_" + pondId][pondTSId];
          const noOfPmsInPond = Object.keys(pondTS.pmsSubscribe).length; // updating pond feed
          pondTS.feed -= pmTS.feed; // updating pm collapse table feed
          if (noOfPmsInPond === 1) {
            delete indexOnPondTSToGroupPmTS[pondTSId];
            delete pondTSTableData[selectedDay + "_" + pondId][pondTSId];
          } else if (pondTS.pmsSubscribe[pmId]) {
            // removing the feed of the pm from pond totalFeed
            pmTS.unSubscribeFromPond(pondTS, indexOnPondTSToGroupPmTS);
            delete pondTS.pmsSubscribe[pmId];
          }
          delete indexOnPmTSToPondTS[pmTSId];
          delete pmTSTableData[dayPondIdPmIdKey][pmTSId];
        }
      }
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        indexOnPondTSToGroupPmTS
      );
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
    },
    DELETE_POND_TS_FROM_POND_TS: function(
      state,
      { selectedDay, pondId, pondTSId }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;
      const currDayKey = selectedDay + "_" + pondId;
      const currTS = pondTSTableData[currDayKey][pondTSId];
      const objCurrPmIdPmTSId = currTS.pmsSubscribe;
      const pmKeys = Object.keys(objCurrPmIdPmTSId);
      pmKeys.forEach(pmId => {
        const currDayPmTSId = `${selectedDay}_${pondId}_${pmId}`;
        delete indexOnPmTSToPondTS[objCurrPmIdPmTSId[pmId]];
        delete pmTSTableData[currDayPmTSId][objCurrPmIdPmTSId[pmId]];
      });
      delete indexOnPondTSToGroupPmTS[pondTSId];
      delete pondTSTableData[currDayKey][pondTSId];
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        indexOnPondTSToGroupPmTS
      );
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
    },
    INIT_DATE_RANGE: function(state, dateRange) {
      state.dateRange = dateRange;
    },
    INIT_PERMISSIONS: function(state, tabItem) {
      state.permissions = tabItem;
    },
    INIT_PM_TS_TABLE_DATA: function(
      state,
      { selectedDay, pondId, pmId, pmTSObj }
    ) {
      state.dayPmIdToTsIdToTs[
        `${selectedDay}_${pondId}_${pmId}`
      ] = Object.assign(
        {},
        state.dayPmIdToTsIdToTs[`${selectedDay}_${pondId}_${pmId}`] || {},
        pmTSObj
      );
      state.dayPmIdToTsIdToTs = Object.assign({}, state.dayPmIdToTsIdToTs);
    },
    INIT_POND_TS_TABLE_DATA: function(
      state,
      { selectedDay, pondId, pondTSObj }
    ) {
      state.dayPondIdToTsIdToTs[selectedDay + "_" + pondId] = Object.assign(
        {},
        pondTSObj
      );
      state.dayPondIdToTsIdToTs = Object.assign({}, state.dayPondIdToTsIdToTs);
    },
    INIT_INDEX_POND_TS_TO_GROUP_PM_TS: function(
      state,
      { indexOnPondTSToGroupPmTS }
    ) {
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        state.indexOnPondTSToGroupPmTS,
        indexOnPondTSToGroupPmTS
      );
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        state.indexOnPondTSToGroupPmTS
      );
    },
    INIT_INDEX_PM_TS_TO_POND_TS: function(state, { indexOnPmTSToPondTS }) {
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
    },
    INIT_SELECTED_DAY: function(state, selectedDay) {
      state.selectedDay = selectedDay;
    },
    INIT_SELECTED_POND: function(state, selectedPond) {
      state.selectedPond = selectedPond;
    },
    INIT_STORE_STATE: function(state) {
      Object.assign(state, cloneDeep(INIT_STATE));
    },
    SET_ALL_PONDS_SCHED_STATS: function(state, allPondsSchedStats) {
      state.allPondsSchedStats = allPondsSchedStats;
    },
    SET_PM_COUNT: function(state, pmCount) {
      state.pmCountArray = pmCount;
    },
    SET_DATE_QUERY_TYPE: function(state, dateQueryType) {
      state.dateQueryType = dateQueryType;
    },
    SET_DATE_RANGE: function(state, dateRange) {
      state.dateRange = dateRange;
    },
    SET_EXPANDED_PM_KEYS: function(state, { pondId, pmId }) {
      state.expandedPmKeys = Object.assign({}, state.expandedPmKeys, {
        [pondId]: pmId
      });
    },
    SET_EXPANDED_POND_KEYS: function(state, expandedPondKeys) {
      state.expandedPondKeys = expandedPondKeys;
    },
    SET_PM_COLLAPSE_TABLE_DATA: function(
      state,
      { pondId, selectedDay, pmCollapseTableData }
    ) {
      state.pondIdToDtSecsToPmCollapseTableData = Object.assign(
        {},
        state.pondIdToDtSecsToPmCollapseTableData,
        {
          [selectedDay + "_" + pondId]: pmCollapseTableData
        }
      );
    },
    SET_POND_COLLAPSE_TABLE_DATA: function(state, pondCollapseTableData) {
      state.pondCollapseTableData = pondCollapseTableData;
    },
    SET_POND_ID_POND_DETAILS: function(state, ObjPondIdPondWithPmsAndMode) {
      state.pondIdWithDetails = ObjPondIdPondWithPmsAndMode;
    },
    SET_POND_LIST_LOADING: function(state, isPondListLoading) {
      state.isPondListLoading = isPondListLoading;
    },
    SET_POND_SCHED_LOADING: function(state, isPondSchedLoading) {
      state.isPondSchedLoading = isPondSchedLoading;
    },
    SET_SELECTED_POND_PM_DETAILS: function(state, arrPmDetails) {
      state.selectedPondPmDetails = arrPmDetails;
    },
    SET_SELECTED_DAY_PM_DETAILS: function(state, arrPmDetails) {
      // getting data from pond mother's api
      const existingPmsMap = state.selectedPondPmDetailsToPmId;
      state.selectedDayPmDetails = arrPmDetails.reduce((acc, pm) => {
        if (existingPmsMap[pm.device_id]) {
          acc.push(cloneDeep(existingPmsMap[pm.device_id]));
        } else {
          const nPm = new PondMother();
          nPm._id = pm.device_id;
          nPm.pond_mother_id = pm.device_id;
          nPm.code = pm.device_code;
          acc.push(nPm);
        }
        return acc;
      }, []);
      console.log(state.selectedDayPmDetails);
    },
    SET_SELECTED_DAY_ST_DETAILS: function(state, arrStDetails) {
      const existingPmsMap = state.selectedPondStDetails.reduce((acc, st) => {
        acc[st._id] = st;
        return acc;
      }, {});
      state.selectedDayStDetails = arrStDetails.map(st => {
        if (existingPmsMap[st.device_id]) {
          return cloneDeep(existingPmsMap[st.device_id]);
        }
        const nSt = new ShrimpTalk();
        nSt._id = st.device_id;
        nSt.pond_mother_id = st.device_id;
        nSt.hex_code = st.device_code;
        return nSt;
      });
    },
    SET_SELECTED_POND_PM_DETAILS_TO_PM_ID: function(state, arrPmDetails) {
      state.selectedPondPmDetailsToPmId = arrPmDetails.reduce((acc, pm) => {
        acc[pm._id] = pm;
        return acc;
      }, {});
    },
    SET_SELECTED_POND_ST_DETAILS: function(state, arrStDetails) {
      state.selectedPondStDetails = arrStDetails.map(st => {
        const newSt = new ShrimpTalk();
        newSt.castToSTObj(st);
        return newSt;
      });
    },
    SET_SELECTED_POND_SCHED: function(state, schedule) {
      state.selectedPondSched = schedule;
    },
    SET_ST_ID_STS_DETAILS: function(state, stDetailsToStId) {
      state.stDetailsToStId = stDetailsToStId;
    },
    SET_TS_STATUS_AT_UI: function(
      state,
      { selectedDay, currPmTSId, currPondTSId, pondId, pmId, status }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const currPmTSKey = `${selectedDay}_${pondId}_${pmId}`;
      const currPondTSKey = selectedDay + "_" + pondId;
      const currDayPondSchedules = pondTSTableData[currPondTSKey];
      const userTimeZoneString = state.userTimeZoneString;
      let currPondTS;

      if (currPmTSId) {
        const currDayPmTimeSlots = pmTSTableData[currPmTSKey];
        const currTS = currDayPmTimeSlots[currPmTSId];
        currPondTSId = indexOnPmTSToPondTS[currTS.ui_id];
      }

      if (currPondTSId) {
        currPondTS = currDayPondSchedules[currPondTSId];
      }

      if (!currPondTS) return;
      currPondTS.checkAndUpdateTSStatus(
        selectedDay,
        pmTSTableData,
        userTimeZoneString
      );
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
    },
    SET_SELECTED_PM_IDS: function(state, arrPmIds) {
      state.selectedPmIds = arrPmIds;
    },
    SET_USER_TIME_ZONE_STRING: function(state, userTimeZoneString) {
      state.userTimeZoneString = userTimeZoneString;
    },
    UPDATE_PM_TS_FEED_CONFIG_PARAMS: function(
      state,
      { prop, value, selectedDay, pmId, pondId, currTSId, userTimeZoneString }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const currPmTSKey = `${selectedDay}_${pondId}_${pmId}`;
      const currPondTSKey = selectedDay + "_" + pondId;
      const currDayPondSchedules = pondTSTableData[currPondTSKey];
      const currDayPmTimeSlots = pmTSTableData[currPmTSKey];
      const currTS = currDayPmTimeSlots[currTSId];
      const currSubscribedPondTSId = indexOnPmTSToPondTS[currTSId];
      const pondTSSubscribeByCurrTS = currSubscribedPondTSId
        ? currDayPondSchedules[currSubscribedPondTSId]
        : undefined;
      const objectToUpdate = {
        updtPropVal: value,
        eventType: "UPDATE"
      };

      switch (prop) {
        case "total_feed_kgs":
          currTS.total_feed_kgs = objectToUpdate;
          break;

        case "ocf_g":
          currTS.ocf_g = objectToUpdate;
          break;
        case "mode_g":
          currTS.mode_g = objectToUpdate;
          break;
        case "feeding_level_g":
          currTS.feeding_level_g = objectToUpdate;
          break;
        case "feed_gap_mins":
          currTS.feed_gap_mins = objectToUpdate;
          break;

        default:
          throw new Error(
            `Unknown Property ${prop} in handleChangeInput method`
          );
        // break;
      }
      if (TS_PM_MODES[currTS.mode] === PM_MODES.SCHEDULE) {
        currTS.updtFeedGap(userTimeZoneString);
      }
      if (pondTSSubscribeByCurrTS) {
        pondTSSubscribeByCurrTS.refresh(selectedDay, pmTSTableData);
      }
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
    },
    UPDATE_PM_TS_TIME: function(
      state,
      { prop, value, selectedDay, pmId, pondId, currTSId, userTimeZoneString }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const indexOnPmTSToPondTS = state.indexOnPmTSToPondTS;
      const indexOnPondTSToGroupPmTS = state.indexOnPondTSToGroupPmTS;
      const currPmTSKey = `${selectedDay}_${pondId}_${pmId}`;
      const currPondTSKey = selectedDay + "_" + pondId;
      const currDayPondSchedules = pondTSTableData[currPondTSKey];
      const currDayPmTimeSlots = pmTSTableData[currPmTSKey];
      const currTS = currDayPmTimeSlots[currTSId];
      const currSubscribedPondTSId = indexOnPmTSToPondTS[currTSId];
      const pondTSSubscribeByCurrTS = currSubscribedPondTSId
        ? currDayPondSchedules[currSubscribedPondTSId]
        : undefined;
      currTS[prop] = {
        updtPropVal: value,
        eventType: "UPDATE"
      }; // const currVal
      // need to join if the any pond TS exist with same from time
      if (TS_PM_MODES[currTS.mode] === PM_MODES.SCHEDULE) {
        currTS.updtFeedGap(userTimeZoneString);
      }
      const mergingByProps = ["from_time", "ui_status"];
      let foundMatchingTS = currTS.findPondTSHavingSameArrProps(
        Object.values(currDayPondSchedules),
        mergingByProps,
        currSubscribedPondTSId
      );
      const NoMatchPondTSAndPresentTSHasOnly1Pm =
        foundMatchingTS === undefined &&
        Object.keys(pondTSSubscribeByCurrTS.pmsSubscribe).length === 1;
      const hasPropInMergePropArr = mergingByProps.includes(prop);

      if (!hasPropInMergePropArr || NoMatchPondTSAndPresentTSHasOnly1Pm) {
        pondTSSubscribeByCurrTS.s_time = currTS.s_time;
        pondTSSubscribeByCurrTS.s_time_secs = currTS.s_time_secs;
        pondTSSubscribeByCurrTS.refresh(selectedDay, pmTSTableData);
        pondTSTableData[selectedDay + "_" + pondId][
          pondTSSubscribeByCurrTS.ui_id
        ] = pondTSSubscribeByCurrTS;
      } else if (hasPropInMergePropArr) {
        if (pondTSSubscribeByCurrTS) {
          currTS.unSubscribeFromPond(
            pondTSSubscribeByCurrTS,
            indexOnPondTSToGroupPmTS
          );
          const pmsSubscribeCount = Object.keys(
            pondTSSubscribeByCurrTS.pmsSubscribe
          ).length;
          if (pmsSubscribeCount === 0) {
            // updating the pondTS to currTS Value
            delete indexOnPondTSToGroupPmTS[currSubscribedPondTSId];
            delete pondTSTableData[selectedDay + "_" + pondId][
              currSubscribedPondTSId
            ];
          } else {
            pondTSSubscribeByCurrTS.refresh(selectedDay, pmTSTableData);
          }
        }
        if (!foundMatchingTS) {
          foundMatchingTS = currTS.createPondTSToJoin(pondId);
          pondTSTableData[selectedDay + "_" + pondId][
            foundMatchingTS.ui_id
          ] = foundMatchingTS;
        } else {
          foundMatchingTS.joinPMWithSameProperty(currTS);
        }
        indexOnPondTSToGroupPmTS[foundMatchingTS.ui_id] =
          foundMatchingTS.pmsSubscribe;
        indexOnPmTSToPondTS[currTS.ui_id] = foundMatchingTS.ui_id;
        foundMatchingTS.created_by =
          Object.keys(foundMatchingTS.pmsSubscribe).length > 1
            ? CREATED_BY.IT_SELF
            : CREATED_BY.POND_MOTHER;
        foundMatchingTS.refresh(selectedDay, pmTSTableData);
      }
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
      state.indexOnPondTSToGroupPmTS = Object.assign(
        {},
        indexOnPondTSToGroupPmTS
      );
      state.indexOnPmTSToPondTS = Object.assign({}, indexOnPmTSToPondTS);
    },
    UPDATE_POND_TS_FEED_CONFIG_PARAMS: function(
      state,
      { prop, value, selectedDay, pondId, currTSId, userTimeZoneString }
    ) {
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const currPondTSKey = selectedDay + "_" + pondId;
      const currDayPondSchedules = pondTSTableData[currPondTSKey];
      const currTS = currDayPondSchedules[currTSId];
      const objectToUpdate = {
        updtPropVal: value,
        eventType: "UPDATE"
      };

      switch (prop) {
        case "total_feed_kgs":
          currTS.total_feed_kgs = objectToUpdate;
          currTS.updtPmsFeed(selectedDay, pmTSTableData);
          break;

        case "ocf_g":
          currTS.ocf_g = objectToUpdate;
          currTS.updtPmsOCF(selectedDay, pmTSTableData);
          break;

        case "mode_g":
          currTS.mode_g = objectToUpdate;
          currTS.updtPmsMode(selectedDay, pmTSTableData);
          break;

        case "feeding_level_g":
          currTS.feeding_level_g = objectToUpdate;
          currTS.updtPmsFeedingLevel(selectedDay, pmTSTableData);
          break;

        default:
          throw new Error(
            `Unknown Property ${prop} in handleChangeInput method`
          );
        // break;
      }
      if (TS_PM_MODES[currTS.mode] === POND_MODES.SCHEDULE) {
        currTS.updtPondFeedGap(selectedDay, pmTSTableData, userTimeZoneString);
      }
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
    },
    UPDATE_POND_TS_TIME: function(
      state,
      { prop, value, selectedDay, pondId, currTSId, userTimeZoneString }
    ) {
      // init step
      const pondTSTableData = state.dayPondIdToTsIdToTs;
      const pmTSTableData = state.dayPmIdToTsIdToTs;
      const currPondTSKey = selectedDay + "_" + pondId;
      const currDayPondSchedules = pondTSTableData[currPondTSKey];
      const currTS = currDayPondSchedules[currTSId];
      currTS[prop] = {
        updtPropVal: value,
        eventType: "UPDATE"
      };
      currTS.updtPmsSubscribeTime(selectedDay, pmTSTableData, prop);
      if (TS_PM_MODES[currTS.mode] === POND_MODES.SCHEDULE) {
        currTS.updtPondFeedGap(selectedDay, pmTSTableData, userTimeZoneString);
      }
      state.dayPondIdToTsIdToTs = Object.assign({}, pondTSTableData);
      state.dayPmIdToTsIdToTs = Object.assign({}, pmTSTableData);
    },
    SET_SELECTED_POND_FEEDING_LEVEL: function(
      state,
      feedingLevelValueWhenNoSchedule
    ) {
      state.feedingLevelValueWhenNoSchedule = feedingLevelValueWhenNoSchedule;
    }
  },
  actions: {
    clearStoreState: function(context) {
      context.commit("INIT_STORE_STATE");
    },
    addPmTSToPmTSTableAndPondTSTable: function(
      context,
      { selectedDay, pmId, pondId, siblingTSId, mode }
    ) {
      const stTimings =
        mode === PM_MODES.AUTOMATIC
          ? context.getters.getSelectedPondPmIdToStTimings[pmId]
          : {};
      context.commit("ADD_PM_TS_TO_PM_TS_TABLE_DATA_AND_POND_TS_TABLE", {
        selectedDay,
        pmId,
        pondId,
        siblingTSId,
        stTimings: stTimings,
        pm_mode: mode
      });
    },
    initUserTimeZoneString: function(context) {
      context.commit(
        "SET_USER_TIME_ZONE_STRING",
        context.rootGetters["user/getUserTimeZoneString"]
      );
    },
    addPondTSToPondTSTableData: function(
      context,
      { pondId, selectedDay, pmsObj, siblingTSId, mode }
    ) {
      const pmStTimings =
        mode === PM_MODES.AUTOMATIC
          ? context.getters.getSelectedPondPmIdToStTimings
          : {};
      console.log("mode--", mode);
      context.commit("ADD_POND_TS_TO_POND_TS_TABLE_DATA_AND_PM_TS_TABLE", {
        pondId,
        selectedDay,
        pmsObj,
        siblingTSId,
        mode,
        pmStTimings
      });
    },
    changeStatusToSaveForMultipleDays: function(
      context,
      isSaveSchedForMultipleDays
    ) {
      context.commit(
        "CHANGE_IS_SAVE_SCHED_FOR_MULTIPLE_DAYS",
        isSaveSchedForMultipleDays
      );
    },
    changeDateRange: async function(context) {
      context.commit("SET_POND_LIST_LOADING", true);
      context.commit("SET_POND_SCHED_LOADING", true);
      try {
        await Promise.all([
          context.dispatch("fetchAllPondDetails"),
          context.dispatch("fetchListOfPondsWithSchedDetails")
        ]);
      } catch (err) {
        if (store.hasModule("pondMotherSchedules")) {
          context.commit("SET_POND_SCHED_LOADING", false);
        }
        throw err;
      } finally {
        if (store.hasModule("pondMotherSchedules")) {
          context.commit("SET_POND_LIST_LOADING", false);
        }
      }
      const selectedPondId = context.getters.getSelectedPond._id;
      const pondDetailsToPondId =
        context.getters.getPondDetailsToPondIdHavingPms;
      let firstPond = pondDetailsToPondId[selectedPondId];
      if (!firstPond) {
        firstPond = Object.values(pondDetailsToPondId)[0];
      }
      if (!firstPond) {
        console.assert("No ponds availble");
        context.commit("SET_POND_SCHED_LOADING", false);
        return;
      }
      context.commit("INIT_SELECTED_POND", firstPond);
      try {
        await Promise.all([
          context.dispatch("initUserTimeZoneString"),
          context.dispatch("fetchSelectedDaySelectedPondSchedDetails"),
          context.dispatch("fetchSelectedPondPmDetails"),
          context.dispatch("fetchSelectedPondStDetails"),
          context.dispatch("fetchPMCount")
          // context.dispatch("fetchSelectedDayStsDetails")
        ]);
        await context.dispatch("fetchSelectedDayPmsDetails");
        await context.dispatch("initPondCollapseTableData");
        await context.dispatch("initSelectedPondTSTableDataForSelectedDay");
      } finally {
        if (store.hasModule("pondMotherSchedules")) {
          context.commit("SET_POND_SCHED_LOADING", false);
        }
      }
    },
    changeDateQueryType: function(context, dateQueryType) {
      context.commit("SET_DATE_QUERY_TYPE", dateQueryType);
    },
    changeInExpandPmKeys: function(context, { pondId, pmId }) {
      context.commit("SET_EXPANDED_PM_KEYS", {
        pondId,
        pmId
      });
    },
    clearPondTSTableData: function(context, { days }) {
      const pondId = context.getters.getSelectedPond._id;
      const pmIds = [
        ...new Set(
          days
            .map(eachDay =>
              Object.keys(context.getters.getPondToPmsMapInSchedByDate(eachDay))
            )
            .flat(1)
        )
      ];
      context.commit("CLEAR_POND_TS_TABLE_DATA", {
        pondId,
        pmId: pmIds,
        days
      });
    },
    createPondSchedule: async function(context, schedule) {
      await context.dispatch("schedules/createSchedules", schedule, {
        root: true
      });
    },
    updatePondSchedule: async function(context, schedule) {
      await context.dispatch("schedules/updateSchedules", schedule, {
        root: true
      });
    },
    updateTodayPondSchedule: async function(context, schedule) {
      await context.dispatch("schedules/updateTodaySchedules", schedule, {
        root: true
      });
    },
    createPMSchedule: async function(context, schedule) {
      await context.dispatch("schedules/createPMSchedules", schedule, {
        root: true
      });
    },
    updatePMSchedule: async function(context, schedule) {
      await context.dispatch("schedules/updatePmTimeSlots", schedule, {
        root: true
      });
    },
    deletePMTimeSlots: async function(context, schedule) {
      await context.dispatch("schedules/deletePMTimeSlots", schedule, {
        root: true
      });
    },
    deletePmTSFromPondTS: function(
      context,
      { selectedDay, pondId, pmId, pmTSId }
    ) {
      context.commit("DELETE_PM_TS_FROM_POND_TS", {
        selectedDay,
        pondId,
        pmId,
        pmTSId
      });
    },
    deletePondFutureSchedules: async function(context, pondId) {
      const days = context.getters.getArrOfDaysFromDateRange;
      const pondExistingSchedules =
        context.getters.getExistSchedDtSecsToPondIdToPondSchedDtls;
      const scheduleIdArr = [];
      const daysPondHasSchedules = [];
      days.forEach(day => {
        const schedule = pondExistingSchedules[day + "_" + pondId];
        if (schedule) {
          scheduleIdArr.push(schedule._id);
          daysPondHasSchedules.push(day);
        }
      });

      await context.dispatch("schedules/deleteSchedules", scheduleIdArr, {
        root: true
      });
      await context.dispatch("refreshPondSchedules", {
        days: daysPondHasSchedules
      });
    },
    deleteFutureTSAndSchedules: async function(
      context,
      { pmId, pondId, doRefreshTableData = true }
    ) {
      const days = context.getters.getArrOfDaysFromDateRange;
      const pondExistingSchedules =
        context.getters.getExistSchedDtSecsToPondIdToPondSchedDtls;
      const scheduleIdArr = [];
      const daysPondHasSchedules = [];
      days.forEach(day => {
        const schedule = pondExistingSchedules[day + "_" + pondId];
        if (schedule) {
          scheduleIdArr.push(schedule._id);
          daysPondHasSchedules.push(day);
        }
      });
      const changedDates = context.getters.getDateRange;
      let payload;
      if (pmId === undefined && pondId) {
        payload = context.getters.getSelectedPmIds;
      } else {
        payload = [pmId];
      }
      await context.dispatch(
        "schedules/deletePmSchedules",
        {
          pmIds: payload,
          params: { from_date: changedDates[0], to_date: changedDates[1] }
        },
        {
          root: true
        }
      );
      if (doRefreshTableData) {
        await context.dispatch("refreshPondSchedules", {
          days: daysPondHasSchedules
        });
      }
    },
    deletePondTSFromPondTSTable: function(
      context,
      { selectedDay, pondId, pondTSId }
    ) {
      context.commit("DELETE_POND_TS_FROM_POND_TS", {
        selectedDay,
        pondId,
        pondTSId
      });
    },
    fetchAllPondDetails: async function(context) {
      const location = context.getters.getCurrUserLocation;
      await context.dispatch(
        "pond/fetchAllPonds",
        {
          location_id: location._id,
          get_all: true,
          status: ["ACTIVE"],
          include: [
            "title",
            "pond_mothers",
            "shrimp_talks",
            "size",
            "units",
            "cultivation_date"
          ],
          order_by: "title",
          order_type: "asc"
        },
        {
          root: true
        }
      );
      const pondIdWithDetails = context.rootGetters["pond/getPondsObj"];
      context.commit("SET_POND_ID_POND_DETAILS", pondIdWithDetails);
      const stDetailsToStId = {};
      Object.values(pondIdWithDetails).reduce((acc, pond) => {
        pond.shrimp_talks.forEach(
          st => (acc[st._id] = { shrimp_talk_id: st._id, ...st })
        );
        return acc;
      }, stDetailsToStId);
      context.commit("SET_ST_ID_STS_DETAILS", stDetailsToStId);
    },
    fetchSelectedDaySelectedPondSchedDetails: async function(context) {
      const location = context.getters.getCurrUserLocation;
      const selectedPond = context.getters.getSelectedPond;
      const selectedDay = context.getters.getSelectedDay;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = dateUtils
        .zonedTimeToUtc(
          dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
          "UTC"
        )
        .toISOString();
      const response = await ScheduleService.fetchAScheduleByPondId({
        pondId: selectedPond._id,
        params: {
          location_id: location._id,
          get_all: true,
          date
        }
      });
      context.commit(
        "SET_SELECTED_POND_FEEDING_LEVEL",
        response.data.schedules
      );
      context.commit(
        "SET_SELECTED_POND_SCHED",
        response.data.schedules.length > 0 &&
          response.data.schedules[0].time_slots
          ? response.data.schedules
          : []
      );
    },
    fetchSelectedPondPmDetails: async function(context) {
      const pondId = context.getters.getSelectedPond._id;
      const response = await PondMotherService.fetchPmDetailsByPondId(pondId);
      const pondMothers = response.data.pond_mothers.sort((a, b) => {
        const codeA = a.shrimp_talk_display_code || "";
        const codeB = b.shrimp_talk_display_code || "";
        // Compare the codes lexicographically
        if (codeA > codeB) return -1;
        if (codeA < codeB) return 1;
        if (a.is_master) return -1;
        if (b.is_master) return 1;

        return a.title.localeCompare(b.title);
      });
      // .sort((a, b) => {
      //   return alphaNumericComparator(a.title, b.title);
      // })
      context.commit("SET_SELECTED_POND_PM_DETAILS", pondMothers);
      context.commit("SET_SELECTED_POND_PM_DETAILS_TO_PM_ID", pondMothers);
    },
    fetchPMCount: async function(context) {
      const selectedDay = context.getters.getSelectedDay;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = dateUtils
        .zonedTimeToUtc(
          dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
          "UTC"
        )
        .toISOString();
      const location = context.getters.getCurrUserLocation;
      const params = {
        location_id: location._id,
        get_all: true,
        from_date: date,
        to_date: date
      };
      const response = await PondMotherService.fetchPMCount(params);
      context.commit("SET_PM_COUNT", response.data.pond_pond_mothers_count);
    },
    fetchSelectedDayPmsDetails: async function(context) {
      const pondId = context.getters.getSelectedPond._id;
      // const selectedDay = context.getters.getSelectedDay;
      // Adding from date and to date selected by user
      // const selectedDay = context.getters.getDateRange;
      // const from_date = selectedDay[0] + "T00:00:00.000Z";
      // const to_date = selectedDay[1] + "T23:59:59.999Z";
      // const userTimeZoneString =
      //   context.rootGetters["user/getUserTimeZoneString"];
      // const date = dateUtils
      //   .zonedTimeToUtc(
      //     dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
      //     "UTC"
      //   )
      //   .toISOString();
      const selectedDay = context.getters.getSelectedDay;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = dateUtils
        .zonedTimeToUtc(
          dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
          "UTC"
        )
        .toISOString();
      const response = await PondsService.fetchPondDevicesByDateRange(pondId, {
        device_type: "POND_MOTHER",
        from_date: date,
        to_date: date.split("T")[0] + "T23:59:59.999Z"
      });
      const pondMothers = response.data.pond_device_mapping_logs;
      context.commit("SET_SELECTED_DAY_PM_DETAILS", pondMothers);
    },
    fetchSelectedDayStsDetails: async function(context) {
      const pondId = context.getters.getSelectedPond._id;
      const selectedDay = context.getters.getSelectedDay;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = dateUtils
        .zonedTimeToUtc(
          dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
          "UTC"
        )
        .toISOString();
      const response = await PondsService.fetchPondDevicesByDateRange(pondId, {
        device_type: "SHRIMP_TALK",
        from_date: date,
        to_date: date
      });
      const shrimpTalks = response.data.pond_device_mapping_logs;
      context.commit("SET_SELECTED_DAY_ST_DETAILS", shrimpTalks);
    },
    fetchSelectedPondStDetails: async function(context) {
      const pondId = context.getters.getSelectedPond._id;
      const response = await ShrimpTalkService.fetchStDetailsByPondId(pondId);
      context.commit(
        "SET_SELECTED_POND_ST_DETAILS",
        response.data.shrimp_talks
      );
    },
    fetchListOfPondsWithSchedDetails: async function(context) {
      // const dateRange = context.getters.getDateRange;
      const location = context.getters.getCurrUserLocation;
      const selectedDay = context.getters.getSelectedDay;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const date = dateUtils
        .zonedTimeToUtc(
          dateUtils.zonedTimeToUtc(selectedDay, userTimeZoneString),
          "UTC"
        )
        .toISOString();
      const listQueryParams = {
        location_id: location._id,
        get_all: true,
        date,
        include_mode: true
      };
      const response = await FeedManagementService.fetchAllPondsWithSchedFeedDetails(
        listQueryParams
      );
      context.commit("SET_ALL_PONDS_SCHED_STATS", response.data.schedules);
    },
    initSelectedPondPmCollapseTableData: function(context) {
      const pondId = context.getters.getSelectedPond._id;
      const s_date_secs = context.getters.getSelectedDay;
      const pmArray =
        Object.keys(
          context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate
        ) || [];
      const pmTopmDetails =
        context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate;
      const pmLength = pmArray.length;
      const dayExistPmScheds =
        context.getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      const pmCollapseTableData = {};
      for (let pmIndex = 0; pmIndex < pmLength; pmIndex++) {
        const pmId = pmArray[pmIndex];
        const pm = pmTopmDetails[pmId] || new PondMother();
        const sched = {};
        sched._id = pm._id;
        sched.title = pm.title;
        sched.feed = 0;
        sched.dispensed_feed = 0;
        sched.managed_by = pm.managed_by;
        sched.shrimp_talk_id = pm.shrimp_talk_id;
        sched.feeding_level = pm.feeding_level;
        const dayPondIdPmId = `${s_date_secs}_${pondId}_${pmId}`;
        if (dayExistPmScheds[dayPondIdPmId]) {
          const existPmSched = dayExistPmScheds[dayPondIdPmId];
          sched.feed = existPmSched.feed;
          sched.dispensed_feed = existPmSched.dispensed_feed;
          sched.managed_by = existPmSched.managed_by;
          sched.modified = existPmSched.modified;
          if (sched.managed_by === PM_MODES.AUTOMATIC) {
            sched.shrimp_talk_id = existPmSched.shrimp_talk_id;
          }
        }
        pmCollapseTableData[pmId] = sched;
      }
      context.commit("SET_PM_COLLAPSE_TABLE_DATA", {
        pondId,
        selectedDay: s_date_secs,
        pmCollapseTableData
      });
    },
    initPmTSTableData: async function(context, { pmId }) {
      const isPastDay = context.getters.isPastDay;
      const isFutureDay = context.getters.isFutureDay;
      const dateQueryType = context.getters.getDateQueryType;
      const selectedDay = context.getters.getSelectedDay;
      const pondId = context.getters.getSelectedPond._id;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      const pondMode = context.getters.getSelectedPondModeOnSelectedDate;
      const pmModeBasedOnSelectedDayPondId =
        context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
          .managed_by;
      const pmDetails =
        context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId];
      const pmTSTableDataLength = Object.values(
        context.getters.getPmTSTableData[`${selectedDay}_${pondId}_${pmId}`] ||
          {}
      ).length;
      if (pmTSTableDataLength > 0) return;
      if (Object.keys(pmDetails || {}).length === 0) return;
      const pmIdToStTimings = context.getters.getSelectedPondPmIdToStTimings;
      const pmHasSched =
        context.getters.getExistSchedDtSecsToPmIdToPmSchedDtls[
          `${selectedDay}_${pondId}_${pmId}`
        ];
      const commitInitPmTSTable = function(context, pmTSObj) {
        context.commit("INIT_PM_TS_TABLE_DATA", {
          selectedDay,
          pondId,
          pmId,
          pmTSObj
        });
      };
      let arrTS = [];
      let pmMode = pmDetails.managed_by;
      if (pmHasSched) {
        pmMode = pmModeBasedOnSelectedDayPondId || "NO_MODE";
        if (pmMode === "HYBRID") {
          arrTS = isPastDay
            ? pmHasSched.time_slots.filter(ts => ts.running_mode === "HYBRID")
            : pmHasSched.time_slots.filter(
                ts => ts.running_mode === "HYBRID"
                // ts.managed_by === "SHRIMP_TALK" || ts.managed_by === "FARMER"
              );
        } else {
          arrTS = isPastDay
            ? pmHasSched.time_slots.filter(
                ts =>
                  ts.running_mode === undefined || ts.running_mode === "NORMAL"
              )
            : pmHasSched.time_slots.filter(
                ts =>
                  ts.managed_by === pmMode &&
                  (ts.running_mode === undefined ||
                    ts.running_mode === "NORMAL")
              );
        }
      }
      if (arrTS.length) {
        const { objPmTSIdTS } = PondMotherTS.castArrBackendTSToUiTS(
          pmDetails,
          arrTS,
          dateQueryType,
          pmIdToStTimings,
          userTimeZoneString,
          isPastDay
        );
        commitInitPmTSTable(context, objPmTSIdTS);
        return;
      }
      if (isPastDay) return;
      if (pondMode === POND_MODES.SCHEDULE) {
        // commiting to the state
        commitInitPmTSTable(context, {});
        return;
      }
      if (pondMode === POND_MODES.HYBRID) {
        // await context.dispatch("initPmTSTableOnTodayAndFutureForHybridMode", {
        //   pmDetails,
        //   pmIdToStTimings,
        //   userTimeZoneString,
        //   commitInitPmTSTable
        // });
        commitInitPmTSTable(context, {});
        return;
      }
      if (pondMode === POND_MODES.MIXED) {
        // POND_MODES.HYBRID got changed to mixed due to new mode changes
        await context.dispatch("initPmTSTableOnTodayAndFutureForHybridMode", {
          pmDetails,
          pmIdToStTimings,
          userTimeZoneString,
          commitInitPmTSTable
        });
        return;
      }
      if (isFutureDay) return;
      if (pondMode === POND_MODES.BASIC) {
        await context.dispatch("initPondTSTableOnTodayForBasicMode", {
          pmDetails,
          userTimeZoneString,
          commitInitPmTSTable
        });
        return;
      }
      await context.dispatch("initPmTSTableOnTodayForAutomaticMode", {
        pmIdToStTimings,
        pmDetails,
        userTimeZoneString,
        commitInitPmTSTable
      });
    },
    initPmTSTableOnTodayAndFutureForHybridMode(
      context,
      { pmDetails, pmIdToStTimings, userTimeZoneString, commitInitPmTSTable }
    ) {
      const dateQueryType = context.getters.getDateQueryType;
      const pmTS = PondMotherTS.getCurrentDayNewTS(
        pmDetails,
        pmDetails.managed_by,
        CREATED_BY.POND_MOTHER,
        userTimeZoneString
      );
      pmTS.dateQueryType = dateQueryType;
      if (pmDetails.managed_by === PM_MODES.AUTOMATIC) {
        if (pmIdToStTimings[pmDetails._id] === undefined) {
          commitInitPmTSTable(context, {});
          return;
        }
        const {
          s_time,
          e_time,
          s_time_in_seconds,
          e_time_in_seconds
        } = pmIdToStTimings[pmDetails._id];
        const currTimeInSecs = cmCurrTimeSecs(userTimeZoneString);
        if (s_time_in_seconds > currTimeInSecs) {
          commitInitPmTSTable(context, {});
          return;
        }
        pmTS.s_time = s_time;
        pmTS.s_time_secs = s_time_in_seconds;
        pmTS.e_time = e_time;
        pmTS.e_time_secs = e_time_in_seconds;
      }
      commitInitPmTSTable(context, { [pmTS.ui_id]: pmTS });
    },
    initPondTSTableOnTodayForBasicMode(
      context,
      { pmDetails, userTimeZoneString, commitInitPmTSTable }
    ) {
      const dateQueryType = context.getters.getDateQueryType;
      const pmTS = PondMotherTS.getCurrentDayNewTS(
        pmDetails,
        pmDetails.managed_by,
        CREATED_BY.POND_MOTHER,
        userTimeZoneString
      );
      pmTS.dateQueryType = dateQueryType;
      commitInitPmTSTable(context, { [pmTS.ui_id]: pmTS });
    },
    initPmTSTableOnTodayForAutomaticMode(
      context,
      { pmIdToStTimings, pmDetails, userTimeZoneString, commitInitPmTSTable }
    ) {
      if (pmIdToStTimings[pmDetails._id] === undefined) {
        commitInitPmTSTable(context, {});
        return;
      }
      const dateQueryType = context.getters.getDateQueryType;
      const {
        s_time,
        e_time,
        s_time_in_seconds,
        e_time_in_seconds
        // shrimp_talk_id,
      } = pmIdToStTimings[pmDetails._id];
      const currTimeInSecs = cmCurrTimeSecs(userTimeZoneString);
      if (s_time_in_seconds > currTimeInSecs) {
        commitInitPmTSTable(context, {});
        return;
      }
      const pmTS = PondMotherTS.getCurrentDayNewTS(
        pmDetails,
        pmDetails.managed_by,
        CREATED_BY.POND_MOTHER,
        userTimeZoneString
      );
      pmTS.s_time = s_time;
      pmTS.s_time_secs = s_time_in_seconds;
      pmTS.e_time = e_time;
      pmTS.e_time_secs = e_time_in_seconds;
      pmTS.dateQueryType = dateQueryType;
      commitInitPmTSTable(context, { [pmTS.ui_id]: pmTS });
    },
    initPondCollapseTableData: function(context) {
      const s_date_secs = context.getters.getSelectedDay;
      const selectedPond = context.getters.getSelectedPond;
      const dayExistPondScheds =
        context.getters.getExistSchedDtSecsToPondIdToPondSchedDtls;
      const dayExistPmScheds =
        context.getters.getExistSchedDtSecsToPmIdToPmSchedDtls;
      const pmIds =
        Object.keys(
          context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate
        ) || [];
      const pondCollapseTableData = {};
      const pondIdSelectedDay = {};
      const pond = selectedPond;
      const pondId = pond._id;
      const sched = {};
      sched._id = pond._id;
      sched.title = pond.title;
      sched.size = pond.size;
      sched.units = pond.units;
      sched.feed = 0;
      sched.dispensed_feed = 0;
      // sched.feeding_limit = pond.feeding_limit;
      sched.status = POND_TS_STATUS.TO_BE_RUN;
      const existPondSched = dayExistPondScheds[s_date_secs + "_" + pond._id];
      if (existPondSched) {
        pmIds.reduce((acc, pmId) => {
          const dayPondIdPmId = `${s_date_secs}_${pondId}_${pmId}`;
          if (dayExistPmScheds[dayPondIdPmId]) {
            const existPmSched = dayExistPmScheds[dayPondIdPmId];
            acc.feed += existPmSched.feed;
            acc.dispensed_feed += existPmSched.dispensed_feed;
          }
          return acc;
        }, sched);
        sched.status = existPondSched.status;
      }
      pondCollapseTableData[pondId] = sched;
      pondIdSelectedDay[pondId] = "" + s_date_secs;
      context.commit("SET_POND_COLLAPSE_TABLE_DATA", pondCollapseTableData);
    },
    initPondTSTableData(context, { selectedDay, pondId }) {
      const dateQueryType = context.getters.getDateQueryType;
      const pmIds = context.getters.getSelectedPmIds || [];
      if (pmIds.length === 0) return;
      const allPmTSForADay = pmIds
        .map(pmId =>
          Object.values(
            context.getters.getPmTSTableData[
              `${selectedDay}_${pondId}_${pmId}`
            ] || {}
          )
        )
        .flat(1);
      console.log("allPmTSForADay", allPmTSForADay);
      const existAllPondTSForADay = Object.values(
        context.getters.getPondTSTableData[`${selectedDay}_${pondId}`] || {}
      );
      const pondModeToStrategy = {
        [POND_MODES.BASIC]: {
          strategy: "DEFAULT"
        },
        [POND_MODES.SCHEDULE]: {
          strategy: "PM_TS_PROPERTIES",
          params: [["s_time"]]
        },
        [POND_MODES.AUTOMATIC]: {
          strategy: "STIME_THRESHOLD_STATUS",
          params: [5]
        },
        BASIC: {
          strategy: "DEFAULT"
        },
        SCHEDULE: {
          strategy: "PM_TS_PROPERTIES",
          params: [["s_time"]]
        },
        AUTOMATIC: {
          strategy: "STIME_THRESHOLD_STATUS",
          params: [5]
        },
        [POND_MODES.HYBRID]: {
          strategy: "DEFAULT"
        },
        [POND_MODES.MIXED]: {
          // POND_MODES.HYBRID got changed to mixed due to new mode changes
          strategy: "DEFAULT"
        }
      };
      const pondMode = context.getters.getPondModeBySelectedPmIds;
      console.log("pondMode", pondMode, pondModeToStrategy[pondMode]);
      const allPondTSForADay = PondTS.generatePondTS(
        pondId,
        dateQueryType,
        allPmTSForADay,
        cloneDeep(existAllPondTSForADay),
        pondModeToStrategy[pondMode].strategy,
        pondModeToStrategy[pondMode].params
      );
      const arrPondTSForADay = Object.values(allPondTSForADay);
      const indexOnPondTSToGroupPmTS = arrPondTSForADay.reduce(
        (acc, eachPondTS) => {
          acc[eachPondTS.ui_id] = eachPondTS.pmsSubscribe;
          return acc;
        },
        {}
      );
      const indexOnPmTSToPondTS = arrPondTSForADay.reduce((acc, eachPondTS) => {
        Object.values(eachPondTS.pmsSubscribe).forEach(pmTSId => {
          acc[pmTSId] = eachPondTS.ui_id;
        });
        return acc;
      }, {});
      context.commit("INIT_INDEX_POND_TS_TO_GROUP_PM_TS", {
        indexOnPondTSToGroupPmTS
      });
      context.commit("INIT_INDEX_PM_TS_TO_POND_TS", {
        indexOnPmTSToPondTS: indexOnPmTSToPondTS
      });
      context.commit("INIT_POND_TS_TABLE_DATA", {
        selectedDay,
        pondId,
        pondTSObj: allPondTSForADay
      });
    },
    initSelectedPondTSTableDataForSelectedDay: async function(context) {
      const pmIds = Object.keys(
        context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate || {}
      );
      await context.dispatch("initSelectedPondPmCollapseTableData");
      await pmIds.forEach(async pmId => {
        await context.dispatch("initPmTSTableData", {
          pmId
        });
      });
      await context.dispatch("changePmsSelection", pmIds);
    },
    async changePmsSelection(context, pmIds) {
      const day = context.getters.getSelectedDay;
      const pondId = context.getters.getSelectedPond._id;
      context.commit("SET_SELECTED_PM_IDS", pmIds);
      if (
        (context.getters.getExpandedPmKeysPondId[pondId] || []).length > 0 &&
        pmIds.includes(context.getters.getExpandedPmKeysPondId[pondId][0])
      ) {
        context.dispatch("changeInExpandPmKeys", {
          pondId: pondId,
          pmId: []
        });
      }
      await context.dispatch("initPondTSTableData", {
        selectedDay: day,
        pondId
      });
    },
    savePondLevelSchedule: async function(context) {
      const pondId = context.getters.getSelectedPond._id;
      const isCurrentDay = context.getters.isCurrentDay;
      const isFutureDay = context.getters.isFutureDay;
      const pmTSTableData = context.getters.getPmTSTableData;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      let pmStDetails = {};
      const backSchedDateFormat = "yyyy-MM-dd";
      // init for multiple dates
      const isSaveSchedForMultipleDays =
        context.getters.getIsSaveSchedForMultipleDays;
      const allDates = context.getters.getArrOfDaysFromDateRange;
      const backPayloadScheds = {
        UPDATE: [],
        DELETE_BK_PM_TS: [],
        CREATE: [],
        CLEAR_FUTURE_SCHEDULES: undefined
      };
      const daysToBeCleared = [];
      const selectedDay = context.getters.getSelectedDay;
      const arrPmIds = context.getters.getSelectedPmIds;
      if (isFutureDay) {
        backPayloadScheds.CLEAR_FUTURE_SCHEDULES = {
          pondId,
          doRefreshTableData: false
        };
      }
      arrPmIds.forEach(pmId => {
        const prepareScheds = function(iterDay, selectedDay) {
          const pmMode =
            context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
              .managed_by;
          if (pmMode === PM_MODES.AUTOMATIC || pmMode === "HYBRID") {
            pmStDetails = context.getters.getSelectedPondPmIdToStTimings[pmId];
          }
          const isIterDayIsCurrentDay = context.getters.isCurrentDay;
          const selectedDayPmTSKey = `${selectedDay}_${pondId}_${pmId}`;
          const selectedDayPmTS = pmTSTableData[selectedDayPmTSKey];
          // pond backend schedule
          const iterDayPondSched =
            context.getters.getExistSchedDtSecsToPondIdToPondSchedDtls[
              iterDay + "_" + pondId
            ];
          console.log("selectedDayPmTS", selectedDayPmTS);
          const iterDayBKPmExistSchedDetails =
            context.getters.getExistSchedDtSecsToPmIdToPmSchedDtls[
              `${iterDay}_${pondId}_${pmId}`
            ];
          const methodToPreparePayload = isCurrentDay
            ? PmSchedule.prepareCurrDayBKSchedules
            : PmSchedule.prepareFutureDayBKSchedules;
          return methodToPreparePayload(
            iterDay,
            selectedDay,
            selectedDayPmTS,
            pondId,
            pmId,
            pmMode,
            pmStDetails,
            isIterDayIsCurrentDay,
            iterDayPondSched,
            iterDayBKPmExistSchedDetails,
            userTimeZoneString,
            backSchedDateFormat
          );
        };
        const payload = prepareScheds(selectedDay, selectedDay);
        console.log("payload--", payload);
        if (!payload.CLEAR_FUTURE_SCHEDULES) {
          Object.keys(payload).forEach(key => {
            switch (key) {
              case "CREATE":
                backPayloadScheds[key] = payload[key]
                  ? [...backPayloadScheds[key], payload[key]]
                  : backPayloadScheds[key];
                break;
              default:
                backPayloadScheds[key] = backPayloadScheds[key].concat(
                  payload[key] || []
                );
                break;
            }
          });
          daysToBeCleared.push(selectedDay);
          if (isSaveSchedForMultipleDays) {
            allDates.forEach((day, index) => {
              if (day !== +selectedDay) {
                const payload = prepareScheds(day, selectedDay);
                Object.keys(payload).forEach(key => {
                  if (payload[key]) {
                    backPayloadScheds[key] = backPayloadScheds[key].concat(
                      payload[key] || []
                    );
                  }
                });
                daysToBeCleared.push(day);
              }
            });
          }
        }
        if (backPayloadScheds.CREATE.length > 0) {
          let isManagedBySame = true;
          const backPayloadSchedsCopy = {
            UPDATE: [],
            DELETE_BK_PM_TS: [],
            CREATE: [],
            CLEAR_FUTURE_SCHEDULES: backPayloadScheds.CLEAR_FUTURE_SCHEDULES
          };
          const checkManagedBySameOrNot = function(ts_Data) {
            const baseline = ts_Data[0].managed_by;
            for (let i = 1; i < ts_Data.length; i++) {
              if (ts_Data[i].managed_by !== baseline) {
                return { isSame: false, managed_by: ts_Data[0].managed_by }; // Managed_by is not the same for all records
              }
            }
            return { isSame: true, managedBy: ts_Data[0].managed_by };
          };
          // Object.keys(backPayloadScheds).forEach(key => {
          if (backPayloadScheds.CREATE && backPayloadScheds.CREATE.length > 0) {
            backPayloadScheds.CREATE.forEach(pm_Data => {
              isManagedBySame = checkManagedBySameOrNot(pm_Data.time_slots);
            });
          }
          // });
          if (isManagedBySame.isSame === false) {
            // Object.keys(backPayloadScheds).forEach(key => {
            if (
              backPayloadScheds.CREATE &&
              backPayloadScheds.CREATE.length > 0
            ) {
              backPayloadScheds.CREATE.forEach(pm_Data => {
                pm_Data.time_slots.forEach(ts_Data => {
                  const obj = {
                    end_time: pm_Data.end_time,
                    managed_by: ts_Data.managed_by,
                    pond_mother_id: pm_Data.pond_mother_id,
                    shrimp_talk_id:
                      ts_Data.managed_by === "SHRIMP_TALK"
                        ? pm_Data.shrimp_talk_id
                        : undefined,
                    start_time: pm_Data.start_time,
                    time_slots: [ts_Data]
                  };
                  backPayloadSchedsCopy.CREATE.push(obj);
                });
              });
            }
            // });
            backPayloadScheds.CREATE = cloneDeep(backPayloadSchedsCopy.CREATE);
          } else {
            // Object.keys(backPayloadScheds).forEach(key => {
            if (
              backPayloadScheds.CREATE &&
              backPayloadScheds.CREATE.length > 0
            ) {
              backPayloadScheds.CREATE.forEach(pm_Data => {
                const obj = {
                  end_time: pm_Data.end_time,
                  managed_by: isManagedBySame.managedBy,
                  pond_mother_id: pm_Data.pond_mother_id,
                  shrimp_talk_id:
                    isManagedBySame.managedBy === "SHRIMP_TALK"
                      ? pm_Data.shrimp_talk_id
                      : undefined,
                  start_time: pm_Data.start_time,
                  time_slots: pm_Data.time_slots
                };
                backPayloadSchedsCopy.CREATE.push(obj);
              });
            }
            // });
            backPayloadScheds.CREATE = cloneDeep(backPayloadSchedsCopy.CREATE);
          }
        }
      });
      console.log("backPayloadScheds====", backPayloadScheds);
      // based on the action calling the api
      await context.dispatch("savePmLevelSchedulesToBK", {
        backPayloadScheds,
        pondId,
        daysToBeCleared
      });
    },
    savePMSchedule: async function(context, { action, payload }) {
      switch (action) {
        case "UPDATE":
          await context.dispatch("updatePMSchedule", payload);
          break;
        case "CREATE":
          await context.dispatch("createPMSchedule", payload);
          break;
        case "DELETE_BK_PM_TS":
          await context.dispatch("schedules/deletePmTSFromSchedules", payload, {
            root: true
          });
          break;
        case "CLEAR_FUTURE_SCHEDULES":
          await context.dispatch("deleteFutureTSAndSchedules", payload);
          break;
      }
    },
    savePondMotherLevelSchedules: async function(context, { pondId, pmId }) {
      const isCurrentDay = context.getters.isCurrentDay;
      const isFutureDay = context.getters.isFutureDay;
      const pmTSTableData = context.getters.getPmTSTableData;
      const userTimeZoneString =
        context.rootGetters["user/getUserTimeZoneString"];
      let pmStDetails = {};
      const backSchedDateFormat = "yyyy-MM-dd";
      // init for multiple dates
      const isSaveSchedForMultipleDays =
        context.getters.getIsSaveSchedForMultipleDays;
      const allDates = context.getters.getArrOfDaysFromDateRange;
      const prepareScheds = function(iterDay, selectedDay) {
        const pmMode =
          context.getters.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
            .managed_by;
        if (pmMode === PM_MODES.AUTOMATIC || pmMode === "HYBRID") {
          pmStDetails = context.getters.getSelectedPondPmIdToStTimings[pmId];
        }
        const isIterDayIsCurrentDay = context.getters.isCurrentDay;
        const selectedDayPmTSKey = `${selectedDay}_${pondId}_${pmId}`;
        const selectedDayPmTS = pmTSTableData[selectedDayPmTSKey];
        // pond backend schedule
        const iterDayPondSched =
          context.getters.getExistSchedDtSecsToPondIdToPondSchedDtls[
            iterDay + "_" + pondId
          ];
        const iterDayBKPmExistSchedDetails =
          context.getters.getExistSchedDtSecsToPmIdToPmSchedDtls[
            `${iterDay}_${pondId}_${pmId}`
          ];
        const methodToPreparePayload = isCurrentDay
          ? PmSchedule.prepareCurrDayBKSchedules
          : PmSchedule.prepareFutureDayBKSchedules;
        return methodToPreparePayload(
          iterDay,
          selectedDay,
          selectedDayPmTS,
          pondId,
          pmId,
          pmMode,
          pmStDetails,
          isIterDayIsCurrentDay,
          iterDayPondSched,
          iterDayBKPmExistSchedDetails,
          userTimeZoneString,
          backSchedDateFormat
        );
      };
      const backPayloadScheds = {
        UPDATE: [],
        DELETE_BK_PM_TS: [],
        CREATE: [],
        CLEAR_FUTURE_SCHEDULES: undefined
      };
      let isManagedBySame = true;
      const backPayloadSchedsCopy = {
        UPDATE: [],
        DELETE_BK_PM_TS: [],
        CREATE: [],
        CLEAR_FUTURE_SCHEDULES: undefined
      };
      if (isFutureDay) {
        backPayloadScheds.CLEAR_FUTURE_SCHEDULES = {
          pmId,
          pondId,
          doRefreshTableData: false
        };
        backPayloadSchedsCopy.CLEAR_FUTURE_SCHEDULES = {
          pmId,
          pondId,
          doRefreshTableData: false
        };
      }
      const daysToBeCleared = [];
      const selectedDay = context.getters.getSelectedDay;
      const payload = prepareScheds(selectedDay, selectedDay);
      if (!payload.CLEAR_FUTURE_SCHEDULES) {
        Object.keys(payload).forEach(key => {
          switch (key) {
            case "UPDATE":
              backPayloadScheds[key] = payload[key] || [];
              break;
            case "DELETE_BK_PM_TS":
              backPayloadScheds[key] = payload[key] || [];
              break;
            case "CREATE":
              backPayloadScheds[key] = payload[key] ? [payload[key]] : [];
          }
        });
        daysToBeCleared.push(selectedDay);
        if (isSaveSchedForMultipleDays) {
          allDates.forEach((day, index) => {
            if (day !== +selectedDay) {
              const payload = prepareScheds(day, selectedDay);
              // console.log(payload);
              Object.keys(payload).forEach(key => {
                if (payload[key]) {
                  backPayloadScheds[key] = backPayloadScheds[key].concat(
                    payload[key] || []
                  );
                }
              });
              daysToBeCleared.push(day);
            }
          });
        }
        // console.log("backPayloadScheds--before", backPayloadScheds);
        if (backPayloadScheds.CREATE.length > 0) {
          const checkManagedBySameOrNot = function(ts_Data) {
            const baseline = ts_Data[0].managed_by;
            for (let i = 1; i < ts_Data.length; i++) {
              if (ts_Data[i].managed_by !== baseline) {
                return { isSame: false, managed_by: ts_Data[0].managed_by }; // Managed_by is not the same for all records
              }
            }
            return { isSame: true, managedBy: ts_Data[0].managed_by };
          };
          // Object.keys(backPayloadScheds).forEach(key => {
          if (backPayloadScheds.CREATE && backPayloadScheds.CREATE.length > 0) {
            backPayloadScheds.CREATE.forEach(pm_Data => {
              isManagedBySame = checkManagedBySameOrNot(pm_Data.time_slots);
            });
          }
          // });
          if (isManagedBySame.isSame === false) {
            // console.log("if---", backPayloadScheds);
            // Object.keys(backPayloadScheds).forEach(key => {
            if (
              backPayloadScheds.CREATE &&
              backPayloadScheds.CREATE.length > 0
            ) {
              backPayloadScheds.CREATE.forEach(pm_Data => {
                pm_Data.time_slots.forEach(ts_Data => {
                  const obj = {
                    end_time: pm_Data.end_time,
                    managed_by: ts_Data.managed_by,
                    pond_mother_id: pm_Data.pond_mother_id,
                    shrimp_talk_id:
                      ts_Data.managed_by === "SHRIMP_TALK"
                        ? pm_Data.shrimp_talk_id
                        : undefined,
                    start_time: pm_Data.start_time,
                    time_slots: [ts_Data]
                  };
                  backPayloadSchedsCopy.CREATE.push(obj);
                });
              });
            }
            // });
            backPayloadScheds.CREATE = backPayloadSchedsCopy.CREATE;
          } else {
            // console.log("else---", backPayloadScheds);
            // Object.keys(backPayloadScheds).forEach(key => {
            if (
              backPayloadScheds.CREATE &&
              backPayloadScheds.CREATE.length > 0
            ) {
              backPayloadScheds.CREATE.forEach(pm_Data => {
                const obj = {
                  end_time: pm_Data.end_time,
                  managed_by: isManagedBySame.managedBy,
                  pond_mother_id: pm_Data.pond_mother_id,
                  shrimp_talk_id:
                    isManagedBySame.managedBy === "SHRIMP_TALK"
                      ? pm_Data.shrimp_talk_id
                      : undefined,
                  start_time: pm_Data.start_time,
                  time_slots: pm_Data.time_slots
                };
                backPayloadSchedsCopy.CREATE.push(obj);
              });
            }
            // });
            backPayloadScheds.CREATE = backPayloadSchedsCopy.CREATE;
          }
        }
      }
      // based on the action calling the api
      console.log("backPayloadScheds", backPayloadScheds);
      await context.dispatch("savePmLevelSchedulesToBK", {
        backPayloadScheds,
        pondId,
        daysToBeCleared
      });
    },
    savePmLevelSchedulesToBK: async function(
      context,
      { backPayloadScheds, pondId, daysToBeCleared }
    ) {
      try {
        if (backPayloadScheds.CLEAR_FUTURE_SCHEDULES) {
          await context.dispatch("savePMSchedule", {
            action: "CLEAR_FUTURE_SCHEDULES",
            payload: backPayloadScheds.CLEAR_FUTURE_SCHEDULES
          });
        }
        if (backPayloadScheds.DELETE_BK_PM_TS.flat(1).length) {
          await context.dispatch("savePMSchedule", {
            action: "DELETE_BK_PM_TS",
            payload: backPayloadScheds.DELETE_BK_PM_TS.flat(1)
          });
        }
        if (backPayloadScheds.UPDATE.length) {
          await context.dispatch("savePMSchedule", {
            action: "UPDATE",
            payload: backPayloadScheds.UPDATE
          });
        }
        if (backPayloadScheds.CREATE.length) {
          await context.dispatch("savePMSchedule", {
            action: "CREATE",
            payload: backPayloadScheds.CREATE
          });
        }
      } finally {
        await context.dispatch("refreshPondSchedules", {
          days: daysToBeCleared
        });
      }
    },
    refreshPondSchedules: async function(context, { days }) {
      await context.dispatch("clearPondTSTableData", {
        days
      });
      await context.dispatch("changeDateRange");
    },
    updatePmTSFeedConfigParams: function(
      context,
      { prop, value, selectedDay, pmId, pondId, currTSId, userTimeZoneString }
    ) {
      context.commit("UPDATE_PM_TS_FEED_CONFIG_PARAMS", {
        prop,
        value,
        selectedDay,
        pmId,
        pondId,
        currTSId,
        userTimeZoneString
      });
    },
    updatePmTSTime: function(
      context,
      { prop, value, selectedDay, pmId, pondId, currTSId, userTimeZoneString }
    ) {
      context.commit("UPDATE_PM_TS_TIME", {
        prop,
        value,
        selectedDay,
        pmId,
        pondId,
        currTSId,
        userTimeZoneString
      });
    },
    updatePondTSFeedConfigParams: function(
      context,
      { prop, value, selectedDay, pondId, currTSId, userTimeZoneString }
    ) {
      context.commit("UPDATE_POND_TS_FEED_CONFIG_PARAMS", {
        prop,
        value,
        selectedDay,
        pondId,
        currTSId,
        userTimeZoneString
      });
    },
    updatePondTSTime: function(
      context,
      { prop, value, selectedDay, pondId, currTSId, userTimeZoneString }
    ) {
      context.commit("UPDATE_POND_TS_TIME", {
        prop,
        value,
        selectedDay,
        pondId,
        currTSId,
        userTimeZoneString
      });
    }
  }
};
