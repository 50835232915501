const stSettingsConfig = [
  {
    type: "input",
    inputType: "text",
    label: "ST ID",
    model: "v1_code",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "IP Address",
    model: "ip_address",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Gateway IP Address",
    model: "gateway_ip_address",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "External IP Address",
    model: "external_ip_address",
    validationRules: ""
  },
  {
    type: "select",
    label: "Feeding Model",
    model: "feeding_model",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Feed Limit",
    model: "feed_limit",
    validationRules: "required|between:20,400"
  },
  {
    type: "timePicker",
    inputType: "time",
    label: "Start Time",
    model: "start_time",
    id: "start_time",
    validationRules: "required|timeLessThan:@end_time"
  },
  {
    type: "timePicker",
    inputType: "time",
    label: "Stop Time",
    model: "end_time",
    id: "end_time",
    validationRules: "required|timeGreaterThan:@start_time"
  },
  {
    type: "select",
    label: "Gateway Role",
    model: "gateway_role",
    validationRules: ""
  },
  {
    type: "timePicker",
    inputType: "time",
    label: "Pause Start",
    model: "pause_start",
    id: "pause_start",
    validationRules: {
      required: true,
      validPauseStart: {
        start_time: "@start_time",
        pause_end: "@pause_end"
      }
    }
  },
  {
    type: "timePicker",
    inputType: "time",
    label: "Pause End",
    model: "pause_end",
    id: "pause_end",
    validationRules: {
      required: true,
      validPauseEnd: {
        end_time: "@end_time",
        pause_start: "@pause_start"
      }
    }
  },
  {
    type: "select",
    label: "Feeding Level",
    model: "feeding_level",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "string",
    label: "Lora Channel",
    model: "lora_channel",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "Latitude",
    model: "latitude",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Longitude",
    model: "longitude",
    validationRules: ""
  },
  {
    type: "select",
    label: "Feed Gap Type",
    model: "fgi_type",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder 1",
    model: "feeder1",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder1 Gateway Id",
    model: "feeder1_gateway_id",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder1 Gateway Channel",
    model: "feeder1_gateway_channel",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder1 Gateway IP",
    model: "feeder1_gateway_ip",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder 2",
    model: "feeder2",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder2 Gateway Id",
    model: "feeder2_gateway_id",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder2 Gateway Channel",
    model: "feeder2_gateway_channel",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Feeder2 Gateway IP",
    model: "feeder2_gateway_ip",
    validationRules: "required"
  },
  {
    type: "input",
    inputType: "text",
    label: "MAC Address",
    model: "mac_address",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "OS Version",
    model: "os_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Software Version",
    model: "software_version",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Hardware Version",
    model: "hardware_version",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Start Index",
    model: "start_index",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Reset Index",
    model: "reset_index",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Audio Card Gain",
    model: "audio_card_gain",
    validationRules: "required"
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Audio Card AGC",
    model: "audio_card_agc",
    validationRules: ""
  },
  {
    type: "input_number",
    inputType: "number",
    label: "AVG Feed Utilization",
    model: "avg_feed_utilization",
    validationRules: "required"
  },
  {
    type: "input_number",
    inputType: "number",
    label: "Settings Interval (Secs)",
    model: "settings_interval",
    validationRules: "required"
  },
  {
    type: "select",
    inputType: "number",
    label: "Device Mode",
    model: "device_mode",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Service Restart",
    model: "service_restart",
    validationRules: ""
  },

  {
    type: "checkbox",
    inputType: "boolean",
    label: "Reboot",
    model: "pi_reboot",
    validationRules: ""
  },
  {
    type: "switch",
    inputType: "boolean",
    label: "DO Threshold Enable",
    model: "pg_enable",
    validationRules: ""
  },
  {
    type: "select",
    label: "Energy Filters",
    model: "energy_filters",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Test Mode",
    model: "test_mode",
    validationRules: ""
  },
  {
    type: "checkbox",
    inputType: "boolean",
    label: "Median Filter",
    model: "median_filter",
    validationRules: ""
  },
  {
    type: "input",
    inputType: "text",
    label: "Group Settings",
    model: "group_settings",
    validationRules: { validFormat: { format: "JSON" } }
  }
].map(keyConfig => {
  return {
    ...keyConfig,
    property: keyConfig.model,
    getMethodParams: ({ activityLog }) => [
      activityLog,
      `data.${keyConfig.model}`
    ]
  };
});

export default stSettingsConfig;
