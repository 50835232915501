/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: adminRoutes.js
Description: This file contains the router configuration useful for handling the navigation for super admin site.
*/
import indexAdmin from "@/views/indexAdmin";
import store from "@/store";
import view404 from "@/views/view404";
import viewNoInternet from "@/views/viewNoInternet";
import localStore from "@/utils/localStore";
// import { superAdminAccess } from "@/constants/uiStates";
// Super Admin Pages

const feedBlowerTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/feedBlowerTableSA"
  );
const gatewayTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/gatewayTableSA"
  );
const pmTabsSA = () =>
  import(/* webpackChunkName: "adminPages" */ "@/views/superadmin/pmTabs");
const pondGuardTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/pondGuardTableSA"
  );
const shrimpTalkTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/shrimpTalkTableSA"
  );
const shrimpSnapTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/shrimpSnapTableSA"
  );
const customersView = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/customersView"
  );
const usersView = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/users/usersView"
  );
const customerListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/customerListIndividualDetailsSA"
  );
const clientsViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/clientsViewSA"
  );
const dealerTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/dealerTableSA"
  );

const pgListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/pgListIndividualDetailsSA"
  );
const gatewayListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/gatewayListIndividualDetailsSA"
  );
const pmListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/pmListIndividualDetailsSA"
  );
const stListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/stListIndividualDetailsSA"
  );
const ssListIndividualDetailsSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/ssListIndividualDetailsSA"
  );
const clientsIndividualViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/clientsIndividualViewSA"
  );
const DealerDetailsTable = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/DealerDetailsTable"
  );
const accountManagerIndividualViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/accountManagerIndividualViewSA"
  );

const accountManagerTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/accountManagerTableSA"
  );
const salesManagerTableSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/eruvakaConnect/salesManagerTableSA"
  );
const salesManagerIndividualViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/eruvakaConnect/salesManagerIndividualViewSA"
  );
export const userProfile = () =>
  import(
    /* webpackChunkName: "userProfile" */ "@/components/settings/userProfile"
  );
const activityLogIndividualViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/activityLogIndividualViewSA"
  );
// const newReleaseAdminView = () =>
//   import(
//     /* webpackChunkName: "adminPages" */ "@/views/superadmin/newReleaseAdminView"
//   );
const adminHelpSection = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/help/adminHelpSection"
  );
const inquiryLogDataViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/inquiryLogDataViewSA"
  );
const careersDataViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/careersDataViewSA"
  );
const warrantyDataViewSA = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/views/superadmin/warrantyDataViewSA"
  );
export const SubscriptionsYearwise = () =>
  import(
    /* webpackChunkName: "adminPages" */ "@/components/superadmin/adminSubscriptionTab/yearWiseCustomer.vue"
  );
export const CustomerPondSummary = () =>
  import(
    "@/components/superadmin/adminSubscriptionTab/customerPondSummary.vue"
  );
export const PondDetails = () =>
  import("@/components/superadmin/adminSubscriptionTab/pondDetails.vue");

const signout = () => {
  store.dispatch("auth/superAdminSignOut").catch(err => {
    console.error(err);
    throw err;
  });
};
const defaultPagesBasedOnUserType = (userType, next) => {
  switch (userType) {
    case userTypes.super_admin:
      return "/admin/customers";
    default:
      signout();
      return "/sign-in";
  }
};
// const doesUserCanAccess = function(to, from, next) {
//   const superAdminAccessCopy = superAdminAccess;
//   try {
//     const currUserType = localStore.getItem("curr-user-type");
//     if (!currUserType) {
//       signout();
//       return next("/sign-in");
//     }
//     const pageAllowedUserType = to.meta.userTypeAllowed;
//     const permissionsArr = localStore.getItem("USER_DATA_ACCESS")?.permissions; // store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
//     console.log("to.path", superAdminAccessCopy[to?.path.split("/")[2]]);
//     if (
//       permissionsArr &&
//       !permissionsArr.includes(superAdminAccessCopy[to?.path.split("/")[2]])
//     ) {
//       return next("/admin/help");
//     }
//     if (
//       to.path !== "/admin/help" &&
//       !permissionsArr.includes(superAdminAccessCopy[to?.path.split("/")[2]])
//     ) {
//       return next("/admin/help");
//     }
//     if (pageAllowedUserType.indexOf(currUserType) === -1) {
//       return next(defaultPagesBasedOnUserType(currUserType, next));
//     }
//     return next();
//   } catch (err) {
//     console.error(
//       "Check the flow logic and user permissions of the router",
//       err
//     );
//   }
// };
const doesUserCanAccess = function(to, from, next) {
  // const superAdminAccessCopy = superAdminAccess;
  try {
    const currUserType = localStore.getItem("curr-user-type");
    if (!currUserType) {
      signout();
      return next("/sign-in");
    }

    const pageAllowedUserType = to.meta.userTypeAllowed;
    // const permissionsArr = localStore.getItem("USER_DATA_ACCESS")?.permissions;
    // console.log("permissionsArr", permissionsArr);

    // if (!permissionsArr) {
    //   throw new Error("Permissions not found in local storage.");
    // }

    // const targetPermission = superAdminAccessCopy[to?.path.split("/")[2]];
    // console.log("targetPermission", targetPermission);
    // if (!targetPermission) {
    //   throw new Error(`Permission not defined for route ${to.path}`);
    // }

    // const hasPermission = permissionsArr.includes(targetPermission);
    // if (!hasPermission) {
    //   console.warn(`User does not have permission for route ${to.path}`);
    //   return next("/admin/help");
    // }

    if (pageAllowedUserType.indexOf(currUserType) === -1) {
      console.warn(`User type not allowed for route ${to.path}`);
      return next(defaultPagesBasedOnUserType(currUserType, next));
    }

    return next();
  } catch (err) {
    console.error("Error in doesUserCanAccess:", err);
    console.error("Stack Trace:", err.stack);
    console.error("Check the flow logic and user permissions of the router");
  }
};

const userTypes = {
  admin: "ADMIN",
  pond_owner: "POND_OWNER",
  pond_worker: "POND_WORKER",
  pond_technician: "POND_TECHNICIAN",
  pond_manager: "POND_MANAGER",
  no_access: "NO_ACCESS",
  super_admin: "SUPER_ADMIN"
};

// const superAdminAccess = {
//   customers: "Customer",
//   gateways: "Gateway",
//   feedblowers: "FeedBlower",
//   pondmothers: "PondMother",
//   pondguards: "PondGuard",
//   shrimptalks: "ShrimpTalk",
//   shrimpsnaps: "ShrimpSnap",
//   clients: "Client",
//   dealers: "Dealer",
//   account: "Account Manager",
//   "activity-log": "Activity Log",
//   AdminHelp: "Help",
//   "inquiry-log": "Inquiry Log",
//   subscriptions: "Subscriptions",
//   users: "Users"
// };

const comn_admin_pages_meta_data = {
  permissions: [userTypes.super_admin],
  dependOnLocations: false,
  userTypeAllowed: [userTypes.super_admin],
  isPermissionRequired: true
};
export default {
  path: "/admin",
  component: indexAdmin,
  children: [
    {
      path: "/",
      redirect: "help"
    },
    {
      path: "gateways",
      name: "gateways",
      component: gatewayTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_gateway"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "feedblowers",
      name: "feedblowers",
      component: feedBlowerTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_feed_blower"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondmothers",
      name: "pondmothers",
      component: pmTabsSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_pond_mother"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondguards",
      name: "pondguards",
      component: pondGuardTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_pond_guard"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "shrimptalks",
      name: "shrimptalks",
      component: shrimpTalkTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_shrimptalk"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "shrimpsnaps",
      name: "shrimpsnaps",
      component: shrimpSnapTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "shrimpsnap.device_name"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "customers",
      name: "customers",
      component: customersView,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "users",
      name: "users",
      component: usersView,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Users"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "clients",
      name: "clients",
      component: clientsViewSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Clients"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "dealers",
      name: "dealers",
      component: dealerTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "dealers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "account",
      name: "account",
      component: accountManagerTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "account"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "sales-manager",
      name: "sales-manager",
      component: salesManagerTableSA,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "sales-manager"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "sales-manager/:salesmanager_id",
      name: "salesmanagerdetails",
      component: salesManagerIndividualViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Sales Managers List"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondmothers/:pm_id",
      name: "PondMotherDetails",
      component: pmListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_pond_mother"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "activity-log",
      name: "ActivityLogDetails",
      component: activityLogIndividualViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_activity_details"
      },
      beforeEnter: doesUserCanAccess
    },
    // {
    //   path: "new-release",
    //   name: "NewReleaseDetails",
    //   component: newReleaseAdminView,
    //   props: true,
    //   meta: {
    //     ...comn_admin_pages_meta_data,
    //     langKey: "Comn_new_release_details"
    //   },
    //   beforeEnter: doesUserCanAccess
    // },
    {
      path: "help",
      name: "AdminHelp",
      component: adminHelpSection,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_admin_help"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "shrimpsnaps/:ss_id",
      name: "ShrimpSnapDetails",
      component: ssListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "shrimpsnap.device_name"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "pondguards/:pg_id",
      name: "PondGuardDetails",
      component: pgListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_pond_guard"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "gateways/:gateway_id",
      name: "GatewayDetails",
      component: gatewayListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_gateway"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "customers/:customer_id",
      name: "CustomerDetails",
      component: customerListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Customers"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "clients/:client_id",
      name: "clientDetails",
      component: clientsIndividualViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Clients List"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "account/:accountmanager_id",
      name: "accountmanagerdetails",
      component: accountManagerIndividualViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Account Managers List"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "dealers/:dealer_id",
      name: "dealerDetails",
      component: DealerDetailsTable,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "dealers List"
      },
      beforeEnter: doesUserCanAccess
    },

    {
      path: "shrimptalks/:st_id",
      name: "ShrimpTalkDetails",
      component: stListIndividualDetailsSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_shrimptalk"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "profile",
      name: "profile",
      component: userProfile,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Profile"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "404",
      name: "admin404",
      component: view404,
      meta: {
        langKey: "PondLogs"
      }
      // prop: true
    },
    {
      path: "nointernet",
      name: "nointernet",
      component: viewNoInternet,
      meta: {
        langKey: "PondLogs"
      }
    },
    {
      path: "inquiry-log",
      name: "InquiryLogDetails",
      component: inquiryLogDataViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "Comn_inquiry_details"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "careers",
      name: "careersDetails",
      component: careersDataViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "careersDetails"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "warranty",
      name: "warrantyDetails",
      component: warrantyDataViewSA,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "warrantyDetails"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/year-wise",
      name: "Subscriptions-year-wise",
      component: SubscriptionsYearwise,
      props: true,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "admin.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/customer-wise",
      name: "Subscriptions-customer",
      component: CustomerPondSummary,
      meta: {
        ...comn_admin_pages_meta_data,
        langKey: "admin.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    },
    {
      path: "subscriptions/pond-details",
      name: "Subscriptions-pond-details",
      component: PondDetails,
      meta: {
        langKey: "dealer.subscriptions"
      },
      beforeEnter: doesUserCanAccess
    }
  ]
};
