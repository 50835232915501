/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name:
Description: This file contains all API service calls in relation to Ponds
*/
import { $axios } from "../config/axios";
import axios from "axios";

export default {
  fetchAllPonds(params) {
    return $axios.get("/ponds", { params });
  },
  fetchActivePonds(params) {
    return $axios.get("/ponds/active/summary", { params });
  },
  fetchInactivePonds(params) {
    return $axios.get("/ponds/inactive/summary", { params });
  },
  fetchPondPerformance(params) {
    return $axios.get("ponds/performance", { params });
  },
  updateArrPondKgDispenseFeed(payload) {
    return $axios.patch("ponds/kg-dispense-time", payload);
  },
  updateTestPonds(payload) {
    const { pond_id, is_test } = payload;
    return $axios.patch(`ponds/${pond_id}/test-status`, { is_test });
  },
  updatePauseAlerts(payload) {
    const { pond_id, pause_alerts } = payload;
    return $axios.patch(`ponds/${pond_id}/update-alerts-status`, {
      pause_alerts
    });
  },
  fetchPondDevicesByDateRange(pondId, params) {
    return $axios.get(`/ponds/${pondId}/pond-device-mapping-logs`, {
      params: { get_all: true, ...params }
    });
  },
  fetchShrimpTalkAndPondMotherLog(stId, params) {
    console.log("stId, params", stId, params);
    // return $axios.get(
    //   `/ponds/shrimp-talks/${stId}/shrimp-talk-and-pond-mother-log`,
    //   {
    //     params: { ...params }
    //   }
    // );
    return axios.get("../../mock/stpmlogs.json");
  },
  fetchAPond(pondId) {
    return $axios.get("/ponds/" + pondId);
  },
  fetchPondHealthData(params) {
    return $axios.get("/ponds/summary", { params });
  },
  savePondDetails(pondDetails) {
    return $axios.post("/ponds", pondDetails);
  },
  startCultureDetails(pondDetails) {
    return $axios.patch(`/ponds/${pondDetails.pond_id}/cultures`, pondDetails);
  },
  fetchAllFeedTypes(params) {
    return $axios.get("/ponds", { params });
  },
  fetchABWSummary(params) {
    return $axios.get("ponds/ABW-summary", { params });
  },
  saveABWValues(abwSamples) {
    return $axios.post("/ponds/ABW", abwSamples);
  },
  fetchPondLatestABWValues(pondId) {
    return $axios.get(`/ponds/${pondId}/latest-abw-values`);
  },
  fetchPondABWValues(pondId, params) {
    return $axios.get(`/ponds/${pondId}/ABW-samples`, {
      params
    });
  },
  fetchAllHarvestLogs(params) {
    return $axios.get("/ponds/harvests", { params });
  },
  saveHarvestLog(harvestLog) {
    return $axios.post("/ponds/harvests", harvestLog);
  },
  updateHarvestLog(harvestLog) {
    return $axios.patch(`/ponds/harvests/${harvestLog.harvest_id}`, harvestLog);
  },
  deleteHarvestLog(harvest_id) {
    return $axios.delete(`/ponds/harvests/${harvest_id}`);
  },
  deleteABWRecord(abwId) {
    return $axios.delete(`/ponds/ABW/${abwId}`);
  },
  deleteFeedRecord(manualFeedRecordId) {
    return $axios.delete(`/ponds/manual-feed/${manualFeedRecordId}`);
  },
  deleteAllDevicesInPond(pondId) {
    return $axios.delete(`/ponds/${pondId}/devices`);
  },
  updateAPondDetails(pondId, pondDetails) {
    return $axios.patch(`/ponds/${pondId}`, pondDetails);
  },
  enablePondService(pondId, payload) {
    return $axios.patch(`/ponds/${pondId}/update-pond-status`, payload);
  }
};
