/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: i18n.js
Description: This file registers the vue-i18n plugin to the vuejs object and registers the all the locales to the vue-i18n configuration
*/
import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en";
import esLocale from "element-ui/lib/locale/lang/es";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import ptLocale from "element-ui/lib/locale/lang/pt";
import viLocale from "element-ui/lib/locale/lang/vi";
import localStore from "@/utils/localStore";
Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  viLocale.el.datepicker.confirm = "Được";
  viLocale.el.datepicker.cancel = "Hủy";
  Object.assign(messages.en, enLocale);
  Object.assign(messages.es, esLocale);
  Object.assign(messages.pt, ptLocale);
  Object.assign(messages.zh, zhLocale);
  Object.assign(messages.vi, viLocale);
  return messages;
}
const userType = localStore.getItem(`curr-user-type`);
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages(),
  silentTranslationWarn:
    ["PROD"].includes(process.env.VUE_APP_STAGE) ||
    ["SUPER_ADMIN"].includes(userType),
  silentFallbackWarn:
    ["PROD"].includes(process.env.VUE_APP_STAGE) ||
    ["SUPER_ADMIN"].includes(userType)
});
