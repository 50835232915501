/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: activityLog.js
Description: Contains all vuex store functions of activityLog
*/
import userService from "@/services/superadmin/user.js";
import pondService from "@/services/superadmin/pond.js";
import activityLogService from "@/services/superadmin/activityLog.js";
import { cloneDeep, get } from "lodash";
import dateUtils from "../../../utils/dateUtils";
import { alphaNumericComparator } from "@/utils/commonUtils";
import moment from "moment";
import pmSettingsConfig from "@/components/superadmin/device-settings/pm-settings";
import pgSettingsConfig from "@/components/superadmin/device-settings/pg-settings";
import stSettingsConfig from "@/components/superadmin/device-settings/st-settings";
import gatewaySettingsConfig from "../../../components/superadmin/device-settings/gateway-settings";
const getRowGeneratorForActivityLogRecord = function(activityLog) {
  const feedingLevels = { 0: "Normal", 1: "Economical", 2: "Aggressive" };
  const preferences = {
    "dd MMM, yy": "DD MMM, YY",
    "dd MMM": "DD MMM",
    "dd/MM/yyyy": "DD/MM/YYYY",
    "HH:mm": "HH:MM",
    "hh:mm a": "HH:MM AM/PM",
    ac: "acres",
    ha: "hectares",
    ALIAS_NAME: "Alias Name",
    CODE: "Device ID",
    SHOW: "Show",
    HIDE: "Hide",
    dashboardTab: "Dashboard",
    pondInfoTab: "Ponds Info",
    pondAlertsTab: "Ponds Alerts"
  };
  const CumulativeValue = {
    MANUAL: "Manual",
    POND_MOTHER: "PondMother",
    MANUAL_AND_POND_MOTHER: "Manual & PondMother",
    KG: "kg",
    L: "L",
    lb: "Lb",
    FINAL: "Final",
    PARTIAL: "Partial"
  };
  const resourceTypes = {
    FEED: "Feed",
    MEDICINE: "Medicine",
    MINERAL: "Mineral",
    PRO_BIOTIC: "Pro-biotic",
    DO_SUBSTITUTE: "DO Substitute",
    WATER_CONDITIONER: "Water Conditioner",
    CARBON_SOURCE: "Carbon Source",
    DISINFECTANT: "Disinfectant",
    FERTILIZER: "Fertilizer",
    FEED_ADDITIVES: "Feed Additives",
    OTHER: "Others",
    ORGANIC_ACID: "Organic Acid"
  };
  const commonFieldsArr = [
    {
      property: "action",
      getMethodParams: ({ activityLog }) => [activityLog, `action`]
    },
    {
      property: "client",
      getMethodParams: ({ activityLog }) => [activityLog, `client`]
    },
    {
      property: "os",
      getMethodParams: ({ activityLog }) => [activityLog, `mobile_meta_data.os`]
    },
    {
      property: "client_app_version",
      getMethodParams: ({ activityLog }) => [
        activityLog,
        `mobile_meta_data.app_version`
      ]
    },
    {
      property: "ip_address",
      getMethodParams: ({ activityLog }) => [activityLog, `ip`]
    },
    {
      property: "date",
      getMethodParams: ({ activityLog }) => [activityLog, `date`]
    },
    {
      property: "user_type",
      getMethodParams: ({ activityLog }) => {
        if (activityLog.i_user_id) {
          return [activityLog, `i_user_type`];
        }
        return [activityLog, `user_type`];
      }
    },
    {
      property: "user_name",
      getMethodParams: ({ activityLog }) => {
        if (activityLog.i_user_id) {
          return [activityLog, `i_user_id.full_name`];
        }
        return [activityLog, `user_id.full_name`];
      }
    },
    {
      property: "user_email",
      getMethodParams: ({ activityLog }) => {
        if (activityLog.i_user_id) {
          return [activityLog, `i_user_id.email`];
        }
        return [activityLog, `user_id.email`];
      }
    }
  ];
  switch (`${activityLog.category}`) {
    case "SCHEDULES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "SCHEDULES";
            }
          },
          ...commonFieldsArr,
          {
            property: "start_time",
            getMethodParams: ({ activityLog }) => [activityLog, `data.s_time`],
            transform: ({ property_value, activityLog }) => {
              return dateUtils.formatDate(
                dateUtils.parse(
                  property_value,
                  "HH:mm",
                  dateUtils.parse(
                    activityLog.data.date !== undefined
                      ? activityLog.data?.date
                      : activityLog.date,
                    dateUtils.isoFormatString,
                    new Date()
                  )
                ),
                dateUtils.isoFormatString
              );
            }
          },
          {
            property: "end_time",
            getMethodParams: ({ activityLog }) => [activityLog, `data.e_time`],
            transform: ({ property_value, activityLog }) => {
              return dateUtils.formatDate(
                dateUtils.parse(
                  property_value,
                  "HH:mm",
                  dateUtils.parse(
                    activityLog.data.date !== undefined
                      ? activityLog.data?.date
                      : activityLog.date,
                    dateUtils.isoFormatString,
                    new Date()
                  )
                ),
                dateUtils.isoFormatString
              );
            }
          },
          {
            property: "feed",
            getMethodParams: ({ activityLog }) => [activityLog, `data.feed`]
          },
          {
            property: "dispensed_feed",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.dispensed_feed`
            ]
          },
          {
            property: "ocf",
            getMethodParams: ({ activityLog }) => [activityLog, `data.ocf`]
          },
          {
            property: "feed_gap",
            getMethodParams: ({ activityLog }) => [activityLog, `data.feed_gap`]
          },
          {
            property: "off_time",
            getMethodParams: ({ activityLog }) => [activityLog, `data.off_time`]
          },
          {
            property: "on_time",
            getMethodParams: ({ activityLog }) => [activityLog, `data.on_time`]
          },
          {
            property: "action",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.schedule_action`
            ]
          },
          {
            property: "schedule_status",
            getMethodParams: ({ activityLog }) => [activityLog, `data.status`]
          },
          {
            property: "schedule_mode",
            getMethodParams: ({ activityLog }) => [activityLog, `data.mode`]
          },
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          }
        ]
      };
    case "SETTINGS_POND_MOTHER":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PM_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "alias_name",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          },
          {
            property: "hopper_type",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.hopper_type`
            ]
          },
          {
            property: "device_ui_edit",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.device_ui_edit`
            ]
          },
          {
            property: "kg_dispense_time",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.kg_dispense_time`
            ]
          },
          {
            property: "mode",
            getMethodParams: ({ activityLog }) => [activityLog, `data.mode`]
          },
          {
            property: "title",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          },
          ...pmSettingsConfig
        ]
      };
    case "SETTINGS_SHRIMP_TALK":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "ST_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "start_time",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.start_time`
            ]
          },
          {
            property: "end_time",
            getMethodParams: ({ activityLog }) => [activityLog, `data.end_time`]
          },
          {
            property: "pause_start",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.pause_start`
            ]
          },
          {
            property: "pause_end",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.pause_end`
            ]
          },
          {
            property: "feed_limit",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.feed_limit`
            ]
          },
          {
            property: "alias_name",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          },
          {
            property: "feeder_1",
            getMethodParams: ({ activityLog }) => [activityLog, `data.feeder1`]
          },
          {
            property: "feeder_2",
            getMethodParams: ({ activityLog }) => [activityLog, `data.feeder2`]
          },
          {
            property: "feeder_3",
            getMethodParams: ({ activityLog }) => [activityLog, `data.feeder3`]
          },
          {
            property: "pmStatus",
            getMethodParams: ({ activityLog }) => [activityLog, `data.status`]
          },
          {
            property: "feeder_level",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.feeding_level`
            ],
            transform: ({ property_value: propertyValue }) => {
              return feedingLevels[propertyValue];
            }
          },
          {
            property: "settings_interval",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.settings_interval`
            ]
          },
          ...stSettingsConfig
        ]
      };
    case "POND_AND_DEVICE_POND_MOTHER":
    case "POND_AND_DEVICE_POND_GUARD":
    case "POND_AND_DEVICE_SHRIMP_TALK":
    case "USER_AND_DEVICE_POND_MOTHER":
    case "USER_AND_DEVICE_POND_GUARD":
    case "USER_AND_DEVICE_SHRIMP_TALK":
    case "USER_AND_DEVICE_GATEWAY":
    case "POND_DEVICES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "POND_DEVICES";
            }
          },
          ...commonFieldsArr,
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "device_type",
            getMethodParams: ({ activityLog }) => {
              // console.log("activityLog?.location_id", activityLog);
              // if (activityLog.data.device_type) {
              return [activityLog, `category`];
              // }
            }
          },
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "location",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.location_id) {
                return [activityLog, `location_id.name`];
              }
              return [activityLog, `location`];
            }
          }
        ]
      };
    case "SETTINGS_POND_GUARD":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PG_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "do_config",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.do_alerts_config[0].lower_limit`
            ]
          },
          {
            property: "do_critical",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.do_alerts_config[0].critical_lower_limit`
            ]
          },
          {
            property: "temp_config",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.temperature_alerts_config[0].lower_limit`
            ]
          },
          {
            property: "temp_config_to",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.temperature_alerts_config[0].upper_limit`
            ]
          },
          {
            property: "alias_name",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          },
          ...pgSettingsConfig
        ]
      };
    case "SETTINGS_GATEWAY":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "GT_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "location",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.locations.name`
            ]
          },
          {
            property: "alias_name",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          },
          {
            property: "allow_all_devices",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.allow_all_devices`
            ]
          },
          ...gatewaySettingsConfig
        ]
      };
    case "ABW":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "ABW";
            }
          },
          ...commonFieldsArr,
          {
            property: "user_email",
            getMethodParams: ({ activityLog }) => {
              if (activityLog.i_user_id) {
                return [activityLog, `i_user_id.email`];
              }
              return [activityLog, `user_id.email`];
            }
          },
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "abw",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.abw`];
            }
          },
          {
            property: "survival_percentage",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.survival_percentage`];
            }
          }
        ]
      };
    case "USER_PREFERENCES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PREFERENCES_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "profile_date_format",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.date_format`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          },
          {
            property: "profile_time_format",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.time_format`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          },
          {
            property: "profile_biomass_units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.biomass_units`];
            }
          },
          {
            property: "profile_biomass_per_area_units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.biomass_per_area_units`];
            }
          },
          {
            property: "profile_pond_area_units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.pond_area_units`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          },
          {
            property: "profile_device_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.device_name`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          },
          {
            property: "profile_feed_gap",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.feed_gap`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          },
          {
            property: "profile_location",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.default_location_id.name`];
            }
          },
          {
            property: "profile_default_dashboard_tab",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.default_dashboard_tab`];
            },
            transform: ({ property_value: propertyValue }) => {
              return preferences[propertyValue];
            }
          }
        ]
      };
    case "USER_MANAGEMENT_PROFILE":
    case "USER_MANAGEMENT_REGISTRATION":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "USER_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "sub_user_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `sub_user_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue ? propertyValue.full_name : "";
            }
          },
          {
            property: "sub_user_email",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `sub_user_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue ? propertyValue.email : "";
            }
          },
          {
            property: "user_registration_first_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.first_name`];
            }
          },
          {
            property: "user_registration_first_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.first_name`];
            }
          },
          {
            property: "user_registration_last_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.last_name`];
            }
          },
          {
            property: "user_registration_phone",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.phone`];
            }
          },
          {
            property: "user_registration_email",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.email`];
            }
          },
          {
            property: "profile_country",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.country.name`];
            }
          },
          {
            property: "profile_timezone",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.timezone.name`];
            }
          },
          {
            property: "profile_verification_status",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.email_verified`];
            }
          },
          {
            property: "profile_status",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.status`];
            }
          },
          {
            property: "user_registration_user_type",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.user_type`];
            }
          },
          {
            property: "user_registration_verification_status",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.email_verified`];
            }
          },
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "hopper_type",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.hopper_type`
            ]
          },
          {
            property: "kg_dispense_time",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.kg_dispense_time`
            ]
          },
          {
            property: "mode",
            getMethodParams: ({ activityLog }) => [activityLog, `data.mode`]
          },
          {
            property: "title",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          }
        ]
      };
    case "POND_ALERTS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "POND_ALERT";
            }
          },
          ...commonFieldsArr,
          {
            property: "error_code",
            getMethodParams: ({ activityLog }) => {
              // if (activityLog.data.device_type) {
              return [activityLog, `data.error_code`];
              // }
            }
          },
          {
            property: "device_type",
            getMethodParams: ({ activityLog }) => {
              // if (activityLog.data.device_type) {
              return [activityLog, `data.device_type`];
              // }
            }
          },
          {
            property: "resolved_by",
            getMethodParams: ({ activityLog }) => {
              // if (activityLog.data.resolved_by) {
              return [activityLog, `data.resolved_by`];
              // }
            }
          },
          {
            property: "resolved_date",
            getMethodParams: ({ activityLog }) => {
              // if (activityLog.data.resolved_date) {
              return [activityLog, `data.resolved_date`];
              // }
            }
          },
          {
            property: "resolved_notes",
            getMethodParams: ({ activityLog }) => {
              // if (activityLog.data.resolved_notes) {
              return [activityLog, `data.resolved_notes`];
              // }
            }
          },
          {
            property: "device_code",
            getMethodParams: ({ device }) => [device, `code`]
          },
          {
            property: "device_title",
            getMethodParams: ({ device }) => [device, `title`]
          },
          {
            property: "hopper_type",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.hopper_type`
            ]
          },
          {
            property: "kg_dispense_time",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `data.kg_dispense_time`
            ]
          },
          {
            property: "mode",
            getMethodParams: ({ activityLog }) => [activityLog, `data.mode`]
          },
          {
            property: "title",
            getMethodParams: ({ activityLog }) => [activityLog, `data.title`]
          }
        ]
      };
    case "USER_LOCATIONS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "USER_LOCATIONS";
            }
          },
          ...commonFieldsArr,
          {
            property: "location",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.name`];
            }
          }
        ]
      };
    case "USER_PONDS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "POND_SETTINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "size",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.size`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? Number(propertyValue).toFixed(2)
                : "";
            }
          },
          {
            property: "units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.units`];
            }
          },
          {
            property: "cultivation_date",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.cultivation_date`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM")
                : "";
            }
          },
          {
            property: "post_larva_stocked",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.post_larva_stocked`];
            }
          },
          {
            property: "initial_abw_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.initial_abw_id.abw`];
            }
          },
          {
            property: "status",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.status`];
            }
          }
        ]
      };
    case "MANUAL_POND_FEEDINGS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "MANUAL_POND_FEEDINGS";
            }
          },
          ...commonFieldsArr,
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.feeds.feed_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? propertyValue.name + " - " + propertyValue.feed_type
                : "";
            }
          },
          {
            property: "feedDate",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.date`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY")
                : "";
            }
          },
          {
            property: "feed_dispensed",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.feeds.feed_dispensed`];
            }
          }
        ]
      };
    case "PONDS_HARVEST":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PONDS_HARVEST";
            }
          },
          ...commonFieldsArr,
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "harvestDate",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.date`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY")
                : "";
            }
          },
          {
            property: "abw",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.abw`];
            }
          },
          {
            property: "biomass",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.biomass`];
            }
          },
          {
            property: "biomass_units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue.biomass_units !== undefined
                ? CumulativeValue[propertyValue.biomass_units]
                : "";
            }
          },
          {
            property: "cumulative_feed",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.cumulative_feed`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? CumulativeValue[propertyValue]
                : "";
            }
          },
          {
            property: "type",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.type`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? CumulativeValue[propertyValue]
                : "";
            }
          },
          {
            property: "fcr",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.fcr`];
            }
          }
        ]
      };
    case "PONDS_MEDICINES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PONDS_MEDICINES";
            }
          },
          ...commonFieldsArr,
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "type",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.type`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? resourceTypes[propertyValue]
                : "";
            }
          },
          {
            property: "resource",
            getMethodParams: ({ activityLog }) => {
              if (activityLog.data?.resource) {
                return [activityLog, `data.resource.name`];
              }
              return [activityLog, `data.resource_name`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined ? propertyValue : "";
            }
          },
          {
            property: "quantity",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.quantity`];
            }
          },
          {
            property: "notes",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.notes`];
            }
          }
        ]
      };
    case "PONDS_LAB_TEST":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PONDS_LAB_TEST";
            }
          },
          {
            property: "action",
            getMethodParams: ({ activityLog }) => [activityLog, `action`]
          },
          {
            property: "client",
            getMethodParams: ({ activityLog }) => [activityLog, `client`]
          },
          {
            property: "os",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `mobile_meta_data.os`
            ]
          },
          {
            property: "client_app_version",
            getMethodParams: ({ activityLog }) => [
              activityLog,
              `mobile_meta_data.app_version`
            ]
          },
          {
            property: "date",
            getMethodParams: ({ activityLog }) => [activityLog, `date`]
          },
          {
            property: "user_type",
            getMethodParams: ({ activityLog }) => {
              if (activityLog.i_user_id) {
                return [activityLog, `i_user_type`];
              }
              return [activityLog, `user_type`];
            }
          },
          {
            property: "user_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog.i_user_id) {
                return [activityLog, `i_user_id.full_name`];
              }
              return [activityLog, `user_id.full_name`];
            }
          },
          {
            property: "user_email",
            getMethodParams: ({ activityLog }) => {
              if (activityLog.i_user_id) {
                return [activityLog, `i_user_id.email`];
              }
              return [activityLog, `user_id.email`];
            }
          },
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "lab_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.lab_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined ? propertyValue.title : "";
            }
          },
          {
            property: "ph",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.ph`];
            }
          },
          {
            property: "salinity",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.salinity`];
            }
          },
          {
            property: "do",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.do`];
            }
          },
          {
            property: "temperature",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.temperature`];
            }
          },
          {
            property: "co3",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.co3`];
            }
          },
          {
            property: "hco3",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.hco3`];
            }
          },
          {
            property: "hardness",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.hardness`];
            }
          },
          {
            property: "ammonia",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.ammonia`];
            }
          },
          {
            property: "ammonium",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.ammonium`];
            }
          },
          {
            property: "tan",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.tan`];
            }
          },
          {
            property: "iron",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.iron`];
            }
          },
          {
            property: "green_colonies",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.green_colonies`];
            }
          },
          {
            property: "yellow_colonies",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.yellow_colonies`];
            }
          }
        ]
      };
    case "POND_SCHEDULE_SETS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "POND_SCHEDULE_SETS";
            }
          },
          ...commonFieldsArr,
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "set_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.name`];
            }
          },
          {
            property: "feed_percentage",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.time_slots.feed_percentage`];
            }
          },
          {
            property: "s_time",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.time_slots.s_time`];
            }
          },
          {
            property: "e_time",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.time_slots.e_time`];
            }
          },
          {
            property: "pond_status",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.pond_status`];
            }
          },
          {
            property: "pond_feed_type",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.feed_type_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? propertyValue.name + " " + propertyValue.feed_type
                : "";
            }
          }
        ]
      };
    case "USER_LABS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "USER_LABS";
            }
          },
          ...commonFieldsArr,
          {
            property: "lab_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.title`];
            }
          },
          {
            property: "phone",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.phone`];
            }
          },
          {
            property: "technician_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.technician_details.name`];
            }
          },
          {
            property: "technician_phone",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.technician_details.phone`];
            }
          },
          {
            property: "address",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.address`];
            }
          }
        ]
      };
    case "RESOURCE_VENDORS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "RESOURCE_VENDORS";
            }
          },
          ...commonFieldsArr,
          {
            property: "vendor_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.name`];
            }
          },
          {
            property: "vendor_mobile",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.phone`];
            }
          }
        ]
      };
    case "USER_RESOURCES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "USER_RESOURCES";
            }
          },
          ...commonFieldsArr,
          {
            property: "resource_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.name`];
            }
          },
          {
            property: "resource_type",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.type`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? resourceTypes[propertyValue]
                : "";
            }
          },
          {
            property: "kg_dispensed_time",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.kg_dispensed_time`];
            }
          },
          {
            property: "units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.units`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? CumulativeValue[propertyValue]
                : "";
            }
          }
        ]
      };
    case "STOCK_FEEDS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "STOCK_FEEDS";
            }
          },
          ...commonFieldsArr,
          {
            property: "feed_name",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `resource_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? propertyValue.name +
                    (propertyValue.feed_type
                      ? "-" + propertyValue.feed_type
                      : "")
                : "";
            }
          },
          {
            property: "number_of_units",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.number_of_units`];
            }
          },
          {
            property: "unit_quantity",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.unit_quantity`];
            }
          },
          {
            property: "vendor_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `vendor_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined ? propertyValue.name : "";
            }
          },
          {
            property: "last_updated_at",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.updated_at`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY hh:mm")
                : "";
            }
          },
          {
            property: "purchaseDate",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.date`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY")
                : "";
            }
          }
        ]
      };
    case "STOCK_MEDICINES":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "STOCK_MEDICINES";
            }
          },
          ...commonFieldsArr,
          {
            property: "resource_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `resource_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? propertyValue.name +
                    (propertyValue.feed_type
                      ? "-" + propertyValue.feed_type
                      : "")
                : "";
            }
          },
          {
            property: "numberOfUnits",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.number_of_units`];
            }
          },
          {
            property: "unit_quantity",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.unit_quantity`];
            }
          },
          {
            property: "vendor_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `vendor_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined ? propertyValue.name : "";
            }
          },
          {
            property: "last_updated_at",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.updated_at`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY hh:mm")
                : "";
            }
          },
          {
            property: "purchaseDate",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.date`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined
                ? moment(propertyValue).format("DD MMM YY")
                : "";
            }
          }
        ]
      };
    case "PONDS_AQUASIMS":
      return {
        loop: [{ key: "device_ids", context_key: "device" }],
        itemProperties: [
          {
            property: "log_type",
            getMethodParams: ({ activityLog }) => [activityLog, `log_type`],
            transform: () => {
              return "PONDS_AQUASIMS";
            }
          },
          ...commonFieldsArr,
          {
            property: "aqua_sim_pond_id",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.aqua_sim_pond_id`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue !== undefined ? propertyValue : "";
            }
          },
          {
            property: "pond_name",
            getMethodParams: ({ activityLog }) => {
              if (activityLog?.pond_id) {
                return [activityLog, `pond_id.name`];
              }
              return [activityLog, `pond_name`];
            }
          },
          {
            property: "aqua_sim_auto_schedules",
            getMethodParams: ({ activityLog }) => {
              return [activityLog, `data.aqua_sim_auto_schedules`];
            },
            transform: ({ property_value: propertyValue }) => {
              return propertyValue === true ? "True" : "False";
            }
          }
        ]
      };
    default:
      return [];
  }
};
const createObjMethod = (arrObjProperties, context) => {
  const logTypeMap = {
    POND_AND_DEVICE_POND_MOTHER: "PondMother", // "POND_PM_MAP",
    POND_AND_DEVICE_POND_GUARD: "PondGuard", // "POND_PG_MAP",
    POND_AND_DEVICE_SHRIMP_TALK: "ShrimpTalk", // "POND_ST_MAP",
    USER_AND_DEVICE_SHRIMP_TALK: "ShrimpTalk", // "POND_ST_MAP",
    USER_AND_DEVICE_POND_MOTHER: "PondMother", // "POND_PM_MAP",
    USER_AND_DEVICE_POND_GUARD: "PondGuard", // "POND_PG_MAP",
    USER_AND_DEVICE_GATEWAY: "Gateway",
    POND_AND_DEVICE_FEED_BLOWER: "POND_FB_MAP"
  };
  return arrObjProperties.reduce((accObj, eachProperty) => {
    accObj[eachProperty.property] = get(
      ...eachProperty.getMethodParams(context)
    );
    if (eachProperty.transform) {
      accObj[eachProperty.property] = eachProperty.transform({
        property_value: accObj[eachProperty.property],
        ...context
      });
    }
    if (accObj?.log_type === "POND_DEVICES") {
      accObj.device_type =
        logTypeMap[accObj?.device_type] || accObj.device_type;
    }
    return accObj;
  }, {});
};
const rowGenerator = (
  value,
  index,
  keyArr,
  loopContext,
  arrObjProperties,
  itemStore
) => {
  if (
    index < keyArr.length &&
    Array.isArray(value[keyArr[index].key]) &&
    value[keyArr[index].key]?.length > 0
  ) {
    for (let i = 0; i < value[keyArr[index].key].length; i++) {
      loopContext = {
        [keyArr[index].context_key]: value[keyArr[index].key][i],
        ...loopContext
      };
      return rowGenerator(
        value[keyArr[index].key][i],
        index + 1,
        keyArr,
        loopContext,
        arrObjProperties,
        itemStore
      );
    }
  } else {
    itemStore.push(createObjMethod(arrObjProperties, loopContext));
    return itemStore;
  }
};
const state = {
  selectedPond: {},
  selectedLocation: {},
  selectedUser: {},
  arrUsers: [],
  selectedDateRange: [],
  userLocations: [],
  selectedLocationPonds: [],
  selectedDeviceTypes: ["POND_MOTHER"],
  selectedDevices: {},
  deviceSearchValue: "",
  locationSearchValue: "",
  pondSearchValue: "",
  selectedLogTypes: { value: { ft_key: "", bk_key: [] } },
  selectedEventTypes: ["CREATE", "UPDATE", "DELETE"],
  activityLogBySelectedFilters: [],
  tableLoading: false
};
const getters = {
  getAllPondsInSelectedLocation: (state, getters) => {
    return getters.getPondSearchValue.toLowerCase() !== ""
      ? state.selectedLocationPonds.filter(x =>
          x.title
            .toLowerCase()
            .includes(getters.getPondSearchValue.toLowerCase())
        )
      : state.selectedLocationPonds;
  },
  getTableLoadingStatus: state => state.tableLoading,
  getDeviceSearchValue: state => state.deviceSearchValue,
  getLocationSearchValue: state => state.locationSearchValue,
  getPondSearchValue: state => state.pondSearchValue,
  getSelectedPond: function(state) {
    return state.selectedPond;
  },
  getSelectedUserId(state, getters) {
    return getters.getSelectedUser._id;
  },
  getSelectedUser(state) {
    return state.selectedUser;
  },
  getAllLocationsofUser(state, getters) {
    return getters.getLocationSearchValue.toLowerCase() !== ""
      ? state.userLocations.filter(x =>
          x.name
            .toLowerCase()
            .includes(getters.getLocationSearchValue.toLowerCase())
        )
      : state.userLocations;
  },
  getSelectedLocationId(state, getters) {
    return getters.getSelectedLocation._id;
  },
  getSelectedLocation(state) {
    return state.selectedLocation;
  },
  getSelectedDateRange(state) {
    return state.selectedDateRange;
  },
  getSelectedDeviceTypes(state) {
    return state.selectedDeviceTypes;
  },
  getSelectedDevices(state) {
    return state.selectedDevices;
  },
  getSelectedEventTypes(state) {
    return state.selectedEventTypes;
  },
  getSelectedLogTypes(state) {
    return state.selectedLogTypes;
  },
  getActivityLogDataBySelectedFilters: function(state, getters) {
    return state.activityLogBySelectedFilters
      .map(eachActivityRecord => {
        const rowGeneratorParams = getRowGeneratorForActivityLogRecord(
          eachActivityRecord
        );
        const itemStore = rowGenerator(
          { activityLog: [eachActivityRecord] },
          0,
          [
            { key: "activityLog", context_key: "activityLog" },
            ...rowGeneratorParams.loop
          ],
          {},
          rowGeneratorParams.itemProperties,
          []
        );
        return itemStore;
      })
      .flat(1)
      .filter(x => x.log_type === getters.getSelectedLogTypes.value.ft_key)
      .filter(x => getters.getSelectedEventTypes.includes(x.action));
  },
  getUsers: state => state.arrUsers,
  getUsersObj: function(state, getters) {
    const arrUsers = getters.getUsers;
    const length = arrUsers.length;
    const usersObj = {};
    for (let i = 0; i < length; i++) {
      const user = arrUsers[i];
      usersObj[user._id] = user;
    }
    return usersObj;
  },
  getUserShrimptalksBySelectedPond(state, getters) {
    const pondId = getters.getSelectedPond._id;
    if (!getters.getSelectedUser.shrimp_talks) return [];
    return cloneDeep(getters.getSelectedUser.shrimp_talks)
      .filter(
        shrimptalk =>
          shrimptalk.pond_id === pondId &&
          (getters.getDeviceSearchValue.toLowerCase() === "" ||
            (shrimptalk.title &&
              shrimptalk.title
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())) ||
            (shrimptalk.code &&
              shrimptalk.code
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())) ||
            (shrimptalk.v1_code &&
              shrimptalk.v1_code
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())))
        // (shrimptalk.title || shrimptalk.code || "")
        //   .toLowerCase()
        //   .includes(getters.getDeviceSearchValue.toLowerCase()))
      )
      .map(x => {
        x.device_type = "SHIRMP_TALK";
        x.device_hex_code = x.code;
        x.code = x.v1_code;
        return x;
      })
      .sort((a, b) => alphaNumericComparator(a.code, b.code));
  },
  getUserPondmothersBySelectedPond(state, getters) {
    const pondId = getters.getSelectedPond._id;
    if (!getters.getSelectedUser.pond_mothers) return [];
    return cloneDeep(getters.getSelectedUser.pond_mothers)
      .filter(
        pond_mother =>
          pond_mother.pond_id === pondId &&
          (getters.getDeviceSearchValue.toLowerCase() === "" ||
            (pond_mother.title &&
              pond_mother.title
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())) ||
            (pond_mother.code &&
              pond_mother.code
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())))
        // (pond_mother.title || pond_mother.code || "")
        //   .toLowerCase()
        //   .includes(getters.getDeviceSearchValue.toLowerCase()))
      )
      .map(x => {
        x.device_type = "POND_MOTHER";
        return x;
      })
      .sort((a, b) => alphaNumericComparator(a.code, b.code));
  },
  getUserPondguardsBySelectedPond(state, getters) {
    const pondId = getters.getSelectedPond._id;
    if (!getters.getSelectedUser.pond_guards) return [];
    return cloneDeep(getters.getSelectedUser.pond_guards)
      .filter(
        pond_guard =>
          pond_guard.pond_id === pondId &&
          (getters.getDeviceSearchValue.toLowerCase() === "" ||
            (pond_guard.title &&
              pond_guard.title
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())) ||
            (pond_guard.code &&
              pond_guard.code
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())))
        // (pond_guard.title || pond_guard.code || "")
        //   .toLowerCase()
        //   .includes(getters.getDeviceSearchValue.toLowerCase()))
      )
      .map(x => {
        x.device_type = "POND_GUARD";
        return x;
      })
      .sort((a, b) => alphaNumericComparator(a.code, b.code));
  },
  getUserGatewaysBySelectedPond(state, getters) {
    const pondId = getters.getSelectedPond._id;
    if (!getters.getSelectedUser.gateways) return [];
    return cloneDeep(getters.getSelectedUser.gateways)
      .filter(
        gateway =>
          gateway.pond_id === pondId &&
          (getters.getDeviceSearchValue.toLowerCase() === "" ||
            (gateway.title &&
              gateway.title
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())) ||
            (gateway.code &&
              gateway.code
                .toLowerCase()
                .includes(getters.getDeviceSearchValue.toLowerCase())))
        // (gateway.title || gateway.code || "")
        //   .toLowerCase()
        //   .includes(getters.getDeviceSearchValue.toLowerCase()))
      )
      .map(x => {
        x.device_type = "GATEWAY";
        return x;
      })
      .sort((a, b) => alphaNumericComparator(a.code, b.code));
  }
};
const mutations = {
  SET_USERS_LOCATIONS: function(state, arrLocations) {
    state.userLocations = arrLocations.sort((a, b) =>
      alphaNumericComparator(a.name, b.name)
    );
  },
  SET_SELECTED_LOCATION_ALL_PONDS_DATA: function(state, arrPonds) {
    state.selectedLocationPonds = arrPonds.sort((a, b) =>
      alphaNumericComparator(a.title, b.title)
    );
  },
  SET_APPEND_USERS_DATA: function(state, usersData) {
    if (state.arrUsers.length === 0) {
      state.arrUsers = usersData;
    } else {
      state.arrUsers = state.arrUsers.concat(usersData);
    }
  },
  SET_USERS_DATA: function(state, usersData) {
    state.arrUsers = usersData;
  },
  SET_A_USER_DETAILS: function(state, aUserDetails) {
    state.selectedUser = aUserDetails;
  },
  SET_DATE_RANGE: function(state, dateRange) {
    state.selectedDateRange = dateRange;
  },
  SET_LOCATION: function(state, location) {
    state.selectedLocation = Object.assign(
      {},
      state.selectedLocation,
      location
    );
  },
  SET_POND: function(state, pond) {
    state.selectedPond = Object.assign({}, pond);
  },
  SET_DEVICE_TYPE: function(state, deviceTypes) {
    state.selectedDeviceTypes = deviceTypes;
  },
  SET_DEVICES: function(state, devices) {
    state.selectedDevices = devices;
  },
  SET_EVENT_TYPE: function(state, eventTypes) {
    state.selectedEventTypes = eventTypes;
  },
  SET_LOG_TYPE: function(state, logTypes) {
    state.selectedLogTypes = logTypes;
  },
  SET_ACTIVITY_LOG_DATA: function(state, activityLogData) {
    state.activityLogBySelectedFilters = activityLogData;
  },
  SET_DEVICE_SEARCH_VALUE: function(state, deviceSearchValue) {
    state.deviceSearchValue = deviceSearchValue;
  },
  SET_LOCATION_SEARCH_VALUE: function(state, locationSearchValue) {
    state.locationSearchValue = locationSearchValue;
  },
  SET_POND_SEARCH_VALUE: function(state, pondSearchValue) {
    state.pondSearchValue = pondSearchValue;
  },
  SET_TABLE_LOADING: function(state, loading) {
    state.tableLoading = loading;
  }
};
const actions = {
  fetchAllPondsInLocation: async function(context, { params = {} }) {
    const userId = context.getters.getSelectedUserId;
    const selectedLocationId = context.getters.getSelectedLocationId;
    if (!selectedLocationId) {
      context.commit("SET_SELECTED_LOCATION_ALL_PONDS_DATA", []);
      return;
    }
    const response = await pondService.fetchAllPondsofUser({
      user_id: userId,
      params: { location_id: selectedLocationId, ...params }
    });
    context.commit("SET_SELECTED_LOCATION_ALL_PONDS_DATA", response.data.ponds);
    return response.ponds;
  },
  fetchAllLocationsofUser: async function(context, { params = {} }) {
    const userId = context.getters.getSelectedUserId;
    const response = await pondService.fetchAllLocationsOfUser({
      user_id: userId,
      params
    });
    context.commit("SET_USERS_LOCATIONS", response.data.user_locations);
  },
  fetchAllUserDetails: async function(
    context,
    { infinite_scroll, params = {} }
  ) {
    params.order_by = "first_name";
    params.order_type = "asc";
    const response = await userService.fetchAllUsers({ params });
    const setter = infinite_scroll ? "SET_APPEND_USERS_DATA" : "SET_USERS_DATA";
    if (params.page === 1 && infinite_scroll) {
      context.commit(setter, []);
    }
    context.commit(setter, response.data.users);
    return response.data;
  },
  fetchAUserDetails: async function(context, { user_id, params = {} }) {
    const response = await userService.fetchAUserDetails({
      user_id,
      params
    });
    context.commit("SET_A_USER_DETAILS", response.data.user);
    return response.data.user;
  },
  fetchActivityLog: async function(context, { tableParams }) {
    // console.log(context.getters.getSelectedLogTypes);
    const checkCategoryVal = get(
      context.getters.getSelectedLogTypes,
      "value.bk_key",
      undefined
    );
    if (!checkCategoryVal) {
      throw {
        type: "FAIL_TO_SAVE",
        errors: [
          {
            message: "category is not allowed to be empty"
          }
        ]
      };
    }
    console.log(checkCategoryVal);
    context.commit("SET_TABLE_LOADING", true);

    try {
      const response = await activityLogService.fetchActivityLog({
        params: {
          main_user_id: context.getters.getSelectedUserId,
          device_ids: context.getters.getSelectedDevices,
          pond_ids: context.getters.getSelectedPond._id
            ? [context.getters.getSelectedPond._id]
            : [],
          category: get(
            context.getters.getSelectedLogTypes,
            "value.bk_key",
            undefined
          ),
          from_date: context.getters.getSelectedDateRange[0],
          to_date: context.getters.getSelectedDateRange[1],
          get_all: true
        }
      });
      context.commit("SET_ACTIVITY_LOG_DATA", response.data.data);
    } finally {
      context.commit("SET_TABLE_LOADING", false);
    }
  },
  clearUserData: async function(context) {
    context.commit("SET_USERS_DATA", []);
  },
  clearActivityLogTableData(context) {
    context.commit("SET_ACTIVITY_LOG_DATA", []);
  },
  clearActivityLogType(context) {
    context.commit("SET_LOG_TYPE", { value: { ft_key: "", bk_key: "" } });
  },
  clearCustomerDetails(context) {
    context.commit("SET_USERS_DATA", []);
  },
  changeDateRange(context, dateRange) {
    context.commit("SET_DATE_RANGE", dateRange);
  },
  changeLocation(context, location) {
    context.commit("SET_LOCATION", location);
    context.dispatch("changePond", {});
  },
  changePond(context, pond) {
    context.commit("SET_POND", pond);
    context.dispatch("changeDeviceType", []);
  },
  changeDeviceType(context, deviceType) {
    context.commit("SET_DEVICE_TYPE", deviceType);
    context.commit("SET_DEVICES", []);
    if (deviceType.length === 0) {
      context.commit("SET_LOG_TYPE", { value: { ft_key: "", bk_key: [] } });
    }
  },
  changeEventType(context, eventType) {
    context.commit("SET_EVENT_TYPE", eventType);
  },
  changeLogType(context, logType) {
    context.commit("SET_LOG_TYPE", logType);
    context.commit("SET_ACTIVITY_LOG_DATA", []);
  },
  changeDevices(context, devices) {
    context.commit("SET_DEVICES", devices);
  },
  changeDevicesSearch(context, deviceSearchValue) {
    context.commit("SET_DEVICE_SEARCH_VALUE", deviceSearchValue);
  },
  changeLocationSearch(context, locationSearchValue) {
    context.commit("SET_LOCATION_SEARCH_VALUE", locationSearchValue);
  },
  changePondSearch(context, pondSearchValue) {
    context.commit("SET_POND_SEARCH_VALUE", pondSearchValue);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
